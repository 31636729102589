@font-face {
    font-family: 'Twemoji Country Flags';
    unicode-range: U+1F1E6-1F1FF, U+1F3F4, U+E0062-E0063, U+E0065, U+E0067, U+E006C, U+E006E, U+E0073-E0074, U+E0077, U+E007F;
    src: url('https://cdn.jsdelivr.net/npm/country-flag-emoji-polyfill@0.1/dist/TwemojiCountryFlags.woff2') format('woff2');
}

* {
    font-family: "Twemoji Country Flags", var(--user-defined-font), sans-serif;
}

@import '_variables.less';
@import '_helpers.less';
@import '_plugins.less';
@import '_fonts.less';
@import '_base.less';

@media screen and (min-width: 320px) {
    @import '_320.less';
}

@media screen and (min-width: 380px) {
    @import '_380.less';
}

@media screen and (min-width: 480px) {
    @import '_480.less';
}

@media screen and (min-width: 576px) {
    @import '_576.less';
}

@media screen and (min-width: 768px) {
    @import '_768.less';
}

@media screen and (min-width: 992px) {
    @import '_992.less';
}

@media screen and (min-width: 1200px) {
    @import '_1200.less';
}

@media screen and (min-width: 1400px) {
    @import '_1400.less';
}
