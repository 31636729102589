//.sale-offer{
//    font-size: 18px;
//    padding: 10px 2px 8px 12px;
//}
.page-hotel-reservation{
    .main-heading{
        font-size: 32px;
        line-height: 41px;
    }
}
#occupy{
    font-size: 14px;
}
//.carousel-item{
//    max-height: 360px;
//    height: 360px;
//}
.hotel-booking-price{
    width: 228px;
}
