
/**************************
SECTION RULE VALUE START
**************************/

@full_section_value = 80;

/**************************
SECTION RULE VALUE END
**************************/
.d-sm-inline-block{
    display: inline-flex;
}
.top-header{
    padding-top: 13px;
}
.abs-cloud{
    img{
        margin-top: auto;
        top: 200px;
        left: 0;

    }
}
.icn-calendar-text{
    font-size: 13px;
    img{
        .mr(2);
    }
}

.slider-home{
    .container-offer{
        min-height: 132px;
    }
}
.tab-section{
    .nav-item{
        span{
            display: inline;
            vertical-align: middle;
        }
        a{
            font-size: 16px;
            line-height: 24px;
        }
    }
}

.supra-title{
    font-size: 32px;
    line-height: 41px;
    &.black{
        .mt(30);
    }
}
.supra-subtitle{
    .mb(30);
}
.offerbyday-title{
    font-size: 18px;
    span{
        font-size: 16px;
    }
}
.excursions{
    .carousel-indicators{
        bottom: -20px;
    }
}
.section-title{
    font-size: 16px;
    line-height: 21px;
}
.tab-section{
    .sejur-city-pills{
        .ml(0);
        .mr(0);
        margin-top: 25px !important;
    }
    .nav-item{
        a{
            font-size: 13px;
            padding-left: 12px;
            padding-right: 12px;
        }
        &:first-child{
            a{
                sup{
                    top: 0px;
                }
            }
        }
    }
    &.city-tab-section{
        .nav-item{
            a{
                br{
                    display: none;
                }
                // sup{
                //     top: 7px;
                //     right: 5px;
                // }
            }
        }
    }
    .sejur{
        .nav-item{
            a{
                sup{
                    .pos-abs;
                    top: 0px;
                    right: 0px;
                }
            }
        }
    }
}
.newsletter-form{
    // .pos-rel;
    button{
        right: 0px;
        padding-right: 0px;
    }
}

.newsletter{
    background-position: center;
    .supra-title{
        line-height: 26px;
    }
    .supra-subtitle{
        .mb(20);
    }
}

form{
    label[for="checkbox"]{
        span{
            font-size: 16px;
            line-height: 20px;
            color: black;
        }
    }
}
.social-iframe{
    overflow-y: visible;
    // max-height: 800px;
}
.main-search{
    .nav-item{
        a{
            font-size: 16px;
            padding-left: 16px;
            padding-right: 16px;
        }
    }
}

.head-icon{
    min-height: 87px;
    img{
        box-shadow: 0px -1px 0px 0.1px @sec-brdr;
    }
}
.tab-content-heading{
    font-size: 18px;
    line-height: 23px;
}
footer{
    .ft-contact{
        a{
            font-size: 13px;
        }
    }
}



/*breadcrumbs*/

.breadcrumbs{
    text-align: left;
    li{
        display: inline;
        &::after{
            display: inline-block;
        }
        a{
            font-family: 'Futura PT D';
        }
    }
}

/*listing*/
.box-offer{
    .p-17{
        padding: 21px 20px 21px 0px;
    }
    .img-offer{
        height: 151px;
    }
}
.listing-description{
    h2{
        font-size: 16px;
        line-height: 16px;
    }
    p{
        font-size: 14px;
        line-height: 17px;
    }
}



.reset{
    .mb(0);
}
.listing-featureimg{
    border-radius: 5px 0px 0px 5px;
}
.main-heading{
    .mt(37);
    .mb(15);
}
.main-heading.calatori-albume{
    .mb(15);
}

.col-brdr{
    width: calc(50% - 20px);
    .mt(-6);
    .ml(5);
}
.centeralize{
    top: 50%;
}
.aduaga-album-foto-page{
    .listing-description{
        h2{
            &.aduaga-title{
                font-size: 20px;
                line-height: 26px;
                .mb(0);
            }
        }
    }
}
.xl-float-right{
    float: right;
}
