.page-despre-noi{
    p{
        color: @dark-blk;
    }
}
.feature-banner{
    .border-radius(5px);
}
.badges-img{
    width: 112px;
    height: 112px;
    .border-radius(50%);
    border: 1px solid @green;
    text-align: center;
    line-height: 105px;

    img{
        width: 55px;
    }
}

.badges{
    width: 220px;
    text-align: center;
}
figcaption{
    h3{
        color: @green;
        font-size: 18px;
        line-height: 23px;
        .demi;
        .mb(0);
    }
    p{
        min-height: 42px;
        font-size: 16px;
        line-height: 21px;
    }
}

.carousel-control-next{
    opacity: 1;
    width: 24px;
    img{
        transform: rotate(270deg);
        .pos-abs;
        right: 0;
    }
}
.carousel-control-prev{
    opacity: 1;
    width: 24px;

    img{
        .pos-abs;
        left: 0;
        transform: rotate(90deg);
    }
 }
