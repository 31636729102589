// .demi = font-family: Futura PT D; (font-weight: 600)
// .banner-with-search{
//     background: url('../../img/excursions/pachete-listing-banner.png') no-repeat;
// }
//.listing-img{
//    min-width: 98px;
//    height: 115px;
//    img{
//        height: 100%;
//        object-fit: cover;
//        .border-radius(5px);
//    }
//}

.banner-with-cautare {
  background: #1374BA;
  padding: 25px 0px;

  h1 {
    text-align: center;
    color: @white;
  }
}

.pachete-listing {
  label[for="filter"] {
    width: 100%;

    select {
      .ml(0);
      width: 100%;
    }
  }
}

.listing-img {
  min-width: 98px;
  height: 115px;

  img {
    height: 100%;
    object-fit: cover;
    .border-radius(5px);
  }
}

.heading-20 {
  font-size: 20px;
  line-height: 26px;
}

.listing-pachete-description {
  .mb(10);

  h2 {
    font-size: 14px;
    line-height: 17px;
    color: @black;
    font-family: 'Futura PT';
    .mb(0);
  }

  p {
    font-size: 12px;
    line-height: 15px;
    .demi;
    .mb(5);
  }

  .icn-calendar-text {
    .mb(10);

    img {
      width: 15px;
    }

    font-size: 12px;
    line-height: 15px;
    font-family: 'Futura PT N';

    span {
      .demi;
    }
  }
}

.listing-pachete-price {
  .normal {
    font-family: 'Futura PT N';
  }
  p {
        &.xl-large-font{
            font-family: 'Futura PT' !important;
            font-weight: 700 !important;
            font-size: 26px !important;
        }
    }
}

.line-through {
  color: @line-through;
}

hr {
  border-bottom: 1px solid #EBEBEB;
  opacity: 1;
}

.sale-offer {
  font-size: 10px;
  line-height: 15px;
  padding: 8px 0px 4px 3px;

  span {
    margin: 0px 5px;
  }

  img {
    width: 18px;
  }
}

#min-budget, #max-budget {
  background: url('../../../img/icons/dollar-bag.svg') no-repeat;
  background-position: center left 10px;
}

.resetlink {
  .mt(-10);
}

/*only for mobile - pachete */
.m-average-font {
  font-size: 14px;
  line-height: 18px;
}

.feature-img {
  .border-radius(5px 5px 0px 0px);
}

.xs-middle-font {
  .middle-font;
}

.autocar {
  font-size: 12px;
  line-height: 15px;
  color: @black;
}

.feature-desc {
  font-size: 14px;
  line-height: 18px;
}


.detail-excursie-tabs {
  //background: @blue;
}

.tab-content {
  padding: 0 !important;

  .box {
    border: 1px solid #EAEAEA;
    background: white;
    border-radius: 0 0 5px 5px;
  }

  .tab-pane {
    &.active {
      .box {
        border-top: 1px solid @white;
      }
    }
  }
}

#nav-tabContent {

  .average-font {
    font-size: 14px;
    line-height: 17px;
  }

  ul {
    .ml(12);

    li {
      //background: url('../../../img/icons/list-disc.svg') no-repeat;
      display: block;
      background-position: left 5px top 6px;
      padding-left: 17px;
      .mb(8);

    }
  }

  ol {
    .ml(12);

    li {
      //background: url('../../../img/icons/list-tick.svg') no-repeat;
      display: block;
      background-position: left top 4px;
      padding-left: 17px;
      .mb(8);
    }
  }
}

.bold-heading {
  font-size: 18px;
  line-height: 23px;
  color: @black;
  font-family: 'Futura PT';

  &.white-clr {
    color: white;
  }

  &.sale-offer {
    .demi;
    left: auto;
    right: 0;
    //border-radius: 0px 20px 20px 0px;
    padding: 5px 10px;
  }
}

.island-hotel {
  img {
    width: 100%;
    .border-radius(5px 5px 0px 0px);
  }
}

.island-partaje {
  table {
    width: 100%;
  }

  tr {
    th {
      .demi;
      font-size: 16px;
      line-height: 21px;

      &:last-child {
        text-align: right;
      }
    }

    td {
      font-size: 14px;
      line-height: 17px;
      padding: 8px 0px;
      border-bottom: 1px solid @light-brdr;

      &:first-child {
        .demi;
      }

      &:last-child {
        text-align: right;
      }
    }

    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}

.route-map {
  .border-radius(5px);
}

#nav-tab {
  white-space: nowrap;
  overflow-x: auto;
  display: block;
  border-bottom: none;

  .nav-link {
    display: inline;
    color: white;
    font-size: 14px;
    line-height: 17px;
    padding-bottom: 0px;
    padding-top: 15px;
    opacity: 0.5;

    &.active {
      opacity: 1;
      border: 1px solid #EAEAEA;
      //background: rgb(121, 9, 9) !important;
      ////background: linear-gradient(180deg, #790909 0%, #790909 2%, rgba(255,255,255,1) 20%) !important;;
      //background: linear-gradient(180deg, #790909 10%, #790909 10%, rgba(255,255,255,0) 10%) !important;

      background: rgb(255,214,0);
      background: linear-gradient(180deg, rgba(255,141,7,1) 10%, rgba(255,141,7,1) 10%, rgba(255,255,255,1) 10%);
    }

    img {
      width: 16px;
      margin-right: 5px;
      position: relative;
      top: -2px;

      &.activeimg {
        display: none;
      }
    }

    &::after {
      max-width: 100%;
      background: white;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.departure_dates {
  border: 1px solid @sec-brdr;
  padding-right: 30px;
  // background: url('../../img/icons/chevron-down.svg') no-repeat;
  background-size: 20px;
  background-position: top 16px right 10px;
  cursor: pointer;
  .mt(2);
  height: 40px;

  &:focus {
    border: 1px solid @orange;
  }
}
