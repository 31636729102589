.car-banner-search{
    //background: url('../../../img/car/car-landing-banner.png') no-repeat;
    //background-size: 100% 100%;
    form{
        .form-group{
            height: 45px;
        }
        label[for="checkbox"]{
            span{
                .small-font;
                .black-clr;
            }
        }
        input{
            .small-font;
        }
    }
}

.car-sidebar-filter-content{
    p{
        background-image: url(../../../img/icons/black-chevron-down.svg);
        background-position: center right;
        background-repeat: no-repeat;
        cursor: pointer;
        background-size: 20px;
    }
}
.car-listing{
    background: @blue;
}
input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
}
.car-location{
    background: url('../../../img/icons/hotel-location.svg') no-repeat !important;
    background-position: center left 10px !important;
}
.car-location-list{
    li{
        background: url('../../../img/icons/hotel-location.svg') no-repeat;
        background-position: top 4px left 4px;
    }
}
.datepickerClass{
    background: url('../../../img/icons/icon-calendar.svg') no-repeat !important;
    background-size: 15px 14px !important;
    background-position: center left 10px !important;
}

.from-time{
    background: url('../../../img/icons/from-time.svg') no-repeat !important;
    background-position: center left 10px !important;
}
.to-time{
    background: url('../../../img/icons/to-time.svg') no-repeat !important;
    background-position: center left 10px !important;
}

.bttn{
    &.transparent-btn{
        background: transparent;
        color: @blue;
        border: 1px solid @blue;
    }
    &.brdr{
        border: 1px solid @blue;
        font-size: 12px;
    }
}

/*car listing*/
.vin8{
    font-size: 18px;
    small{
        font-size: 13px;
        top: -10px;
        margin-left: -3px;
    }
}
.car-top-bar{
    border: none;
    .border-radius(5px);
}
.car-notify{
    padding: 6px 15px;
    border: 1px solid @sec-brdr;
    background: white;
    &.left-side{
        .border-radius(5px 0px 0px 5px);
        &.active{
            background: white;
            .border-radius(5px 30px 30px 5px);
            border-right: 1px solid @sec-brdr;
        }
    }
    &.right-side{
        // .border-radius(0px 5px 5px 0px);
        .border-radius(30px 5px 5px 30px);
        background: white;
        &.active{
            background: white;
            // .border-radius(30px 5px 5px 30px);
            border-left: 1px solid @sec-brdr;
        }
    }
}
.active-car-types{
    height: 4px;
    .border-radius(1px);
    background: @sec-brdr;
    width: 100%;
    position: absolute;
    bottom: 3px;
    left: 0px;
    &.activeness{
        background: @orange;
    }
}
.car-types{
    overflow: scroll;
    white-space: nowrap;
    .mx-3-custom{
        margin-left: 10px;
        margin-right: 10px;
        &:first-child{
            .ml(0);
        }
        &:last-child{
            .mr(0);
        }
    }
}
.car-features{
    columns: 2;
    li{
        .mb(20);
        span{
            .ml(10);
        }
    }
}
.make-logo{
    right: 15px;
    top: 15px;
}
.price-block{
    .pos-abs;
    bottom: 23px;
    right: 27px;
}
form select.dropdown-select{
    padding-left: 12px;
}

.body-bg{
    background: @body-bg;
}
.booking-table{
    thead{
        th{
            .small-font;
            .medium-font;
            vertical-align: baseline;

            span{
                font-size: 12px;
                line-height: 15px;
                .normal;
                display: block;
            }
        }
    }
    th{
        &:nth-child(1){
            .small-font;
            .medium-font;
            text-align: left;
            width: 40%;
        }
    }
    td{
        &:nth-child(1){
            .small-font;
            text-align: left;
            .normal;
            cursor: pointer;
            width: 40%;
        }
        p{
            display: none;
            .mb(0);
            .small-font;
        }
    }
    td, th{
        padding: 5px;
        border-bottom: 0px !important;
        input{
            .mb(0);
        }
    }
}
.reservation-summary{
    label[for="checkbox"]{
        span{
            color: @black;
            width: 97%;
            display: inline-block;
            vertical-align: top;
            a{
                .normal;
            }
        }
    }
}



/*success msg*/
.light-green-bg{
    background: rgba(12, 124, 89, 0.1);
    border-radius: 5px;
    width: 100%;
    padding: 16px;
    border-left: 18px solid @green;
}
