.right-detail{
    ul{
        li{
            display: inline;
            background: url('../../../img/account/list-disc.svg') no-repeat;
            background-size: 3px 3px;
            background-position: left center;
            padding-left: 17px;
            .ml(10);

            &:first-child{
                background: none;
                padding-left: 0px;
                .ml(0);
            }
        }
    }
}
