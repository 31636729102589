.d-sm-inline-flex{
    display: inline-flex;
}
.top-header{
    padding-top: 5px;
    padding-bottom: 0;
    .main-logo{
        border: 4px solid @blue;
    }
    .title-logo{
        font-family: 'Open Sans', sans-serif;
        .mb(0);
    }
    .subtitle-logo{
        margin-top: 0;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        line-height: 21px;
        font-weight: 600;
    }
    .search-wrapper{
        input{
            padding-left: 5px;
        }
        button{
            width: 30px;
        }
    }
}
.search-main{
    .mt(7);
}
.contact-info{
    padding-top: 13px;
    a{
        color: white;
    }
    li{
        color: white;
    }
    img{
        .mr(5);
    }
}

.bottom-header{
    ul{
        li{
            display: inline;
            a{
                color: @black;
                padding: 0px 11px 10px 11px;
                font-size: 18px;
                line-height: 23px;
                display: inline-block;
            }
            &:hover{
                .sub-menu{
                    display: block;
                }
            }
            &:first-child{
                a{
                    padding-left: 0px;
                }
            }
            &:last-child{
                a{
                    padding-right: 0px;
                }
            }
        }
    }
}

.carousel-caption{
    right: 30%;
}
#Carousel-sale{
    .container-offer{
        padding: 20px 0 15px 25px;
    }
    .section-title{
        font-size: 12px;
        line-height: 16px;
    }
}

.tab-section{
    .nav-item{
        a{
            font-size: 16px;
            padding-left: 18px;
            padding-right: 18px;
            sup{
                right: -7px;
            }
        }
    }
    .sejur{
        .nav-item{
            a{
                sup{
                    right: -7px;
                }
            }
        }
    }
}
.icn-calendar-text{
    font-size: 13px;
}
.footer{
    .supra-title{
        font-size: 24px;
        line-height: 24px;
        font-family: 'Futura PT D';
    }
}
footer{
    .ft-contact{
        a{
            font-size: 14px;
        }
    }
}
.footer-copyright{
    color: @black;
}

.g-line-ft{
    width: calc(100% - 83px);
}
form{
    .form-group{
        display: inline-block;
        .ml(10);
        &:first-child{
            display: block;
        }
    }
}
.main-search{
    .nav-item{
        a{
            padding-left: 25px;
            padding-right: 25px;
            img{
                width: 30px;
                margin-right: 7px !important;
            }
        }
    }
}
.car-banner-search{
    form{
        .form-group{
            height: auto;
        }
    }
}
.autocomplete-list{
    max-width: 420px;
}
.svg-frame{
    .pos-abs;
    left: 10px;
    .mt(55);
    z-index: -1;
    &.r-side{
        right: 0;
        left: auto;

    }
}
.mt-150{
    .mt(150);
}
.mt--80{
    .mt(-80);
}
.m--150{
    .mt(-150);
}

section{
    .pos-rel;
}
.box-offer{
    .container-offer{
        &.py-47{
            padding-top: 26px;
            padding-bottom: 26px;
        }
    }
    .img-offer{
        height: 171px;
    }

}


.slider-home{
    .container-offer{
        min-height: 168px;
    }
}
/*listing*/
.listing-description{
    h2{
        font-size: 20px;
        line-height: 30px;
    }
    p{
        font-size: 16px;
        line-height: 21px;
    }
}




/*foto single album*/
.big-flower{
    margin: 10px;
    width: calc(100% - 20px);

    img{
        width: calc(100% - 10px) !important;
    }
}

.small-flower-img{
    width: calc(100% - 10px) !important;
    .mb(10);
}
.small-flower{
    margin: 10px;
}

.aduaga-album-foto-page {
    .listing-description{
        h2{
            font-size: 20px;
            line-height: 26px;
        }
    }
}
.centeralize{
    top: 55%;
}

.steps-list{
    .mt(-30);
    ul{
        li{
            color: white;
            display: inline;
            font-size: 20px;
            .mr(13);
            &::before{
                content: '';
                background: url('../../img/icons/long-arrow-right.svg') no-repeat;
                width: 52px;
                height: 21px;
                background-size: 52px 21px;
                display: inline-block;
                vertical-align: middle;
            }
            &:first-child{
                .ml(0);
                &::before{
                    display: none;
                }
                em{
                    .ml(0);
                }
            }
            em{
                border: 5px solid #8BCFFF;
                height: 57px;
                width: 57px;
                margin-right: 7px;
                display: inline-block;
                vertical-align: middle;
                text-align: center;
                line-height: 47px;
                font-size: 20px;
                color: white;
                border-radius: 50%;
                .ml(13);
            }
            &:last-child{
                .mr(0);
            }
            &.active{
                .demi;
                em{
                    .demi;
                    background: #8BCFFF;
                }
            }
        }
    }
}
.sejur-city{
    .offerbyday-title{
        span{
            .demi;
            font-size: 18px;
            line-height: 23px;
        }
    }
}
.occupy-group{
    width: 40%;
}
.main-search{
    #occupy{
        font-size: 14px;
        line-height: 24px;
        height: 40px;
    }
    .submitBtn-group{
        width: 20%;
    }
}

ul.sub-menu{
    .pos-abs;
    z-index: 3;
    left: 0;
    margin-left: 0px !important;
    width: max-content;
    max-width: max-content;
    //padding-left: 15px !important;
    background: #fff;
    box-shadow: 0px 0px 10px -8px;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    padding: 10px 0px 10px 0px;
    display: none;

    li{
        display: list-item;
        padding: 5px 10px 5px 10px;
        a{
            padding-left: 0px !important;
        }
    }
}
.lg-font-24{
    font-size: 24px !important;
    padding: 16px 16px !important;
}
