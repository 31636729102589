.d-sm-inline-block{
    display: inline-flex;
}
.offerbyday-title{
    font-size: 14px;

    span{
        font-size: 14px;
    }
}
.bsize{
    font-size: 18px;
    span{
        font-size: 16px;
    }
}
.abs-cloud{
    img{
        .pos-abs;
        top: 250px;
        left: 0px;
        margin-top: auto;
    }
}

.tab-section{
    .nav-item{
        a{
            sup{
                top: 0px;
                right: 0px;
            }
        }
    }
}
.back-to-top{
    right: 40px;
    bottom: 40px;
    line-height: 60px;
    width: 64px;
    height: 64px;
}

.box-offer{
    .pb-5{
        padding-bottom: 3rem !important;
    }
}

/*listing*/
.listing-description{
    h2{
        font-size: 20px;
        line-height: 26px;

    }
}



.add-albume-calatori{
    border: none;
}

.calatori-albume-page{
    .listing-description{
        h2{
            .mb(12);
        }
    }
}
