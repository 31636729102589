/* landing page city break*/
.landing-city-break{
    .sale-offer{
        span{
            font-size: 12px;
        }
    }
}

.country-list{
    columns: 2;
    li{
        margin-bottom: 10px;
        a{
            text-transform: capitalize;
            color: @black;
            &:hover{
                color: @orange;
                i{
                    color: @orange;
                }
            }
            i{
                color: @black;
                .pos-rel;
                top: 2px;
            }
        }

    }
    &.sub-country{
        columns: 1;
        li{
            .mb(5);
        }
    }
}

.sub-citybreak{
    .mb(27);
    img{
        .border-radius(5px 0px 0px 5px);
        height: 248px;
        width: 100%;
        object-fit: cover;
    }
}




.banner-with-search{
    //background: url('../../../img/city/search-banner.png') no-repeat;
    width: 100%;
    object-fit: cover;
    padding: 25px 0px;
}
.citybreak-listing-search{
    background: @blue;
}
.excursions-search{
    background: white;
    box-shadow: 0px 9px 10px rgba(11, 59, 167, 0.05);
    .border-radius(5px);
    padding: 20px 12px;

    form{
        .form-group{
            .mt(-3);
        }
        select{

            background: url('../../../img/icons/icon-calendar.svg') no-repeat, url('../../../img/icons/select-chevron-down.svg') no-repeat;
            background-size: 16px 16px, 17px 17px;
            background-position: center left 10px, top 11px right 10px;
            &#country{
                background: url('../../../img/icons/country-briefcase.svg') no-repeat, url('../../../img/icons/select-chevron-down.svg') no-repeat;
                background-position: center left 10px, top 11px right 10px;
            }
        }
    }

}


/*dropdown for multiple input*/
.adult-copi-camera{
    display: none;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid @brdr;
    position: absolute;
    background: white;
    z-index: 3;
    width: 100%;

    input{
        -moz-appearance: textfield;
        text-align: center;
        &::-webkit-outer-spin-button,&::-webkit-inner-spin-button{
            -webkit-appearance: none;
        }
    }

}
label[for="occupy"]{
    i{
        cursor: pointer;
    }
    .plus-minus-input input{
        border: 1px solid @brdr;
        border-radius: 5px;
        width: 30%;
        height: 30px;
        font-size: 14px;
        margin-left: 5px;
        margin-right: 5px;
    }
}
em{
    font-style: normal;
}
#occupy{
    cursor: pointer;
    background: url('../../../img/icons/gray-user.svg') no-repeat, url('../../../img/icons/select-chevron-down.svg') no-repeat;
    background-position: center left 10px, top 11px right 10px;
    background-size: 19px 19px;
    border: 1px solid @inpt-brdr;
    padding-left: 38px;
}

#occupy + span{
    img{
        .ml(5);
        .mr(5);
    }
}


.hotel-destination{
    background: url('../../../img/icons/icon-destination.svg') no-repeat !important;
    background-position: center left 10px !important;
    background-size: 19px 19px !important;
}
.hotel-destination-list{
    li{
        background: url('../../../img/icons/icon-destination.svg') no-repeat;
    }
}



.flights-data{
    overflow-x: scroll;
    white-space: nowrap;
    .box-offer{
        width: 325px;
        .mr(20);
        display: inline-block;
        &:last-child{
            .mr(0);
        }
    }
}

.forward-arrow{
    width: 10%;
}
.flight-icon{
    width: 25%;
}
.flight-title{
    width: 60%;
    text-align: center;
}
.flight-time{
    width: 24%;
}

span[for="bagaj"]{
    width: 177px;
    align-self: center;
}


.reservation-form{
    .form-group.checkbox-group{
        height: 40px;
    }
}
.light-green-bg{
    background: rgba(12, 124, 89, 0.1);
}
.br-10{
    .border-radius(10px);
}

.nav-content{
    ol{
        .ml(12);
        li{
            background: url('../../../img/icons/list-tick.svg') no-repeat;
            display: block;
            background-position: left top 4px;
            padding-left: 17px;
            .mb(8);
        }
    }
}
.blog-gradient{
    max-height: 135px;
    overflow-y: hidden;
}
.read-more{
    color: @blue;
    display: block;
    position: absolute;
    bottom: 0;
    right: 20px;
    width: 100%;
    margin: 0;
    padding-top: 50px;
    padding-bottom: 20px;
    background-image: linear-gradient(to bottom, transparent, white, white);
    i{
        .pos-rel;
        top: 2px;
    }
}
