/*pachete reservation*/
.reservation-item{
    ul{
        li{
            .average-font;
            .mb(7);
        }
    }
}
.page-hotel-reservation{
    .m-average-font{
        font-size: 18px;
        line-height: 23px;
    }
}
//.carousel-item{
//    max-height: 250px;
//    height: 250px;
//}
.booking-facility{
    ul{
        li{
            .average-font;
        }
    }
}
.averge-sm-font{
    .average-font;
}
