.flight-travelinfo{
    .g-line{
        width: 95px;
    }

}
.cheapFlight{
    tr{
        td{
            width: 94px;
        }
    }
}
