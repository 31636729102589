
.d-sm-inline-block{
    display: inline-flex;
}
.navbar-toggle{
    width: 110%;
}
.top-header{
    padding-top: 5px;
    .main-logo{
        border: 5px solid @blue;
    }
    .search-wrapper{

        &.d-flex.ms-auto.me-auto{
            height: 33px;
        }
        input{
            height: 33px;
        }
        button{
            width: 35px;
            height: 30px;
            line-height: 30px;
        }
    }
}
.offerbyday-title{
    font-size: 18px;
    span{
        font-size: 12px;
    }
}
.icn-calendar-text{
    font-size: 12px;
}

.carousel-caption{
    right: 15px;
}

.tab-section{
    .nav-item{
        a{
            padding-left: 8px;
            padding-right: 8px;
            font-size: 14px;
            sup{
                top: -7px;
                right: -5px;
            }
        }
        &:first-child{
            a{
                padding-left: 5px;
                sup{
                    top: -5px;
                    right: 0;
                }
            }
        }
        &:last-child{
            a{
                padding-right: 8px;
            }
        }
    }
}

.back-to-top{
    right: 25px;
    bottom: 25px;
    line-height: 45px;
    width: 50px;
    height: 50px;
}
.main-heading{
    font-size: 22px;
    line-height: 31px;
    margin-top: 7px;
    margin-bottom: 25px;
}


.box-offer{
    .p-17{
        padding: 12px;
    }
    .pb-5{
        padding-bottom: 2rem !important;
    }
}





/*breadcrumbs*/

.breadcrumbs{
    text-align: center;
    li{
        display: list-item;
        &::after{
            display: none;
        }
        a{
            font-family: 'Futura PT', serif;
        }
    }
}
.angajare-contacti{
    label[for="checkbox"]{
        span{
            font-size: 16px;
            line-height: 21px;
        }
    }
}
.single-description{
    .mb(37);
    &.mb-xs-0{
        .mb(0);
    }
    h2{
        font-size: 20px;
        line-height: 26px;
    }
    &.single-blog-description{
        .mb(0);
    }
}

.account{
    form{
        .saulgoin{
            span{
                font-size: 15px;
            }
        }
    }
}
.reset{
    .mb(65);
}
.large-font{
    &.email-sent{
        top: -45px;
    }
}


.add-albume-calatori{
    border: 1px solid @blue;
    img{
        .pos-rel;
        top: 2px;
    }
}

.listing-featureimg{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.newsletter-form{
    .pos-rel;
    button{
        right: 0px;
        padding-right: 0px;
    }
}
.sale-offer {
    z-index: 1;
}
