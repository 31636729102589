.travel-briefcase{
    width: 270px;
}
.travel-info{
    //width: 238px;
    .pos-rel;
    li{
        a{
            font-size: 24px;
            line-height: 31px;
            .demi;
            color: @black;
        }
        &.ft-mail{
            a{
                font-family: 'Futura PT N';
            }
        }
    }
    .ft-tel, .ft-mail{
        padding-left: 40px;
        &::before{
            width: 30px;
            height: 30px;
            background-size: 30px 30px;
        }
    }
}
.page-contact{
    h2{
        font-size: 24px;
        line-height: 31px;
        .demi;
    }
}
