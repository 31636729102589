// .bottom-header{
//     .ml(185);
//     .mr(95);
// }
.slider-home{
    .container-offer{
        min-height: auto;
        padding-top: 9px;
        padding-bottom: 9px;
    }
}
form{
    .form-group{
        width: auto;
    }
    label{
        &[for="plecare-date"], &[for="retur-date"], &[for="passengar"]{
            width: 187px;
        }
    }
}
.offerbyday-title{
    font-size: 18px;
}
.tab-section{
    padding: 4px 40px;
    .nav-item{
        a{
            font-size: 20px;
        }
    }
}
.py-47{
    padding-top: 44px;
    padding-bottom: 44px;
}
.box-offer{
    .container-offer{
        &.py-47{
            padding-top: 44px;
            padding-bottom: 44px;
        }
        &.p-17{
            padding: 20px 20px 20px 0px;
        }
    }
}
.container{
    max-width: 1255px;
}
.steps-list{
    ul{
        li{
            .mr(40);
            em{
                .ml(40);
            }
        }
    }
}
.carSearch{
    form{
        input.form-control{
            padding-left: 38px;
        }
        label{
            span{
                .ml(38);
            }
        }
    }
}
