.page-hotel-reservation{
    .m-average-font{
        font-size: 14px;
    }
}

.reservation-form{
    .form-group{
        &.radio-group{
            height: 54px;
            font-size: 16px;
        }
    }
    label[for="checkbox"]{
        span{
            .average-font;
        }
    }
}
.d-xs-block{
    display: flex !important;
}
