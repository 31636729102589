.filteration-by-date-price{
    .heading-18{
        font-size: 18px;
    }
}
.page-flight-listing{
    .heading-20{
        font-size: 20px;
    }
}
.flight-travelinfo{
    .g-line{
        width: 33px;
    }

}
.offandon{
    display: block;
}
.filteration-by-date-price-content{
    display: none;
}
