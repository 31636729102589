/**************************
SECTION RULE VALUE START
**************************/

@full_section_value = 80;

/**************************
SECTION RULE VALUE END
**************************/

/**************************
SECTION HEADER
**************************/
b {
    font-family: 'Futura PT';
}

.recommended-btn-play {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    background: #328fe3;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 100%;
}
.recommended-btn-play button {
    background:transparent;
    color:#fff;
}

.title-page {
    padding-top: 9px;
    padding-bottom: 9px;
    position: absolute;
    background: orange;
    width: 100px;
    height: 61px;
    text-align: center;
    z-index: 1;
    top: 150px;
    font-size: 16px;
    line-height: 21px;
    font-family: 'Futura PT';
    color: white;

    &.r-rotate {
        right: -68px;
        transform: rotate(270deg);
        width: auto;
        height: auto;
        padding: 9px 17px;
        top: 350px;
        border-radius: 5px 5px 0px 0px;
    }
}


.green-bg {
    background: @green;
}

.top-header {
    background: @blue;

    .logo-text {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .title-logo {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        color: white;
    }

    .subtitle-logo {
        margin-top: -50px;
        color: white;
    }


    .search-wrapper.d-flex.ms-auto.me-auto {
        background: white;
        border-radius: 6px;
        top: 32px;
        position: relative;
        width: 403px;
        height: 40px;
        left: calc(50% - 403px / 2 + 5.5px);
    }

    .search-wrapper button {
        width: 42px;
        height: 35px;
        text-align: center;
        line-height: 35px;
        padding: 0;
        background-color: transparent;
    }


    .ctc-infos-wrap {
        top: 20px;
        position: relative;
    }

    .ctc-info {
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: flex-end;
    }

    .text-white-ctc {
        color: white;
        margin-bottom: 3px;
        font-size: 15px;
    }

}

/**************************
SECTION TOP MENU
**************************/
.top-menu {
    padding: 10px;

    .my-account {
        justify-content: flex-end;
    }


    li.nav-item a {
        color: black !important;
        font-style: normal;
        font-weight: 500;
        line-height: 23px;
        margin-right: 14px;
    }

}


/**************************
SECTION TOP OFFER
**************************/

.slider-swipper {

    .img-offer {
        background: #FFFFFF;
        // box-shadow: 0px 0px 10px white(11 59 167 / 10%);
        border-radius: 5px 5px 0px 0px;
    }


    .container-offer {
        padding: 10px;
    }

    .offerbyday {
        display: flex;
        gap: 25px;
    }

    .icn-calendar {
        display: flex;
        gap: 1em;
    }

    .offerbyday-price {
        display: flex;
        justify-content: space-between;
    }

    .offerbyday-title {
        font-weight: bold;
        font-family: 'Futura PT';
    }

}


.carousel {
    // box-shadow: 0px 0px 20px rgba(11, 59, 167, 0.1);
    .border-radius(5px);
    // .sec-brdr(@sec-brdr);
    z-index: 1;
}

.abs-cloud {
    img {
        .pos-abs;
        .mt(-390);
        z-index: 0;
    }
}

.rfloat {
    .pos-rel;
    right: -60px;
}

.swiper-container {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: 200px;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.carousel-indicators [data-bs-target] {
    height: 2px;
    width: 2px;
    .border-radius(50%);
    border: 2px solid @orange;
    background: transparent;
    .opacity(1);
    margin-left: 11px;
    margin-right: 11px;
    content: '';
    color: transparent;
    padding: 2px;

    &.active {
        background: @orange;
    }
}

.supra-title {
    color: @blue;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    font-family: 'Futura PT';

    &.black {
        color: @black;
        .mt(22);
    }
}

.supra-subtitle {
    font-size: 16px;
    line-height: 21px;
    color: @black;
    font-family: 'Futura PT N';
    .mb(22);
}

p {
    color: @black;
    font-family: 'Futura PT N';
}

.sale-offer-sec {
    #Carousel-sale {
        .img-offer {
            height: 100%;
            object-fit: cover;
        }
    }
}

.slider-home {
    .img-offer {
        img {
            object-fit: cover;
            transition: transform 0.2s ease;
            transform: scale(1);

            &:hover {
                transform: scale(1.2);
                transition: transform 0.2s ease;
            }
        }
    }
}

.listing-img {
    overflow: hidden;
    border-radius: 10px;

    img {
        object-fit: cover;
        transition: transform 0.2s ease;
        transform: scale(1);

        &:hover {
            transform: scale(1.2);
            transition: transform 0.2s ease;
        }
    }
}

.albums {
    overflow: hidden;
    border-radius: 5px;

    img {
        width: 330px;
        height: 180px;
        object-fit: cover;
        transition: transform 0.2s ease;
        transform: scale(1);

        &:hover {
            transform: scale(1.2);
            transition: transform 0.2s ease;
        }
    }
}

.box-offer {
    .pos-rel;
    .border-radius(5px);
    .sec-brdr(@sec-brdr);
    background: white;
    //z-index: 1;
    // box-shadow: 0px 0px 10px rgba(11, 59, 167, 0.15);
    .img-offer {
        overflow: hidden;
        .border-radius(5px 5px 0px 0px);
        width: 100%;

        img {
            object-fit: cover;
            transition: transform 0.2s ease;
            transform: scale(1);

            &:hover {
                transform: scale(1.2);
                transition: transform 0.2s ease;
            }
        }
    }

    .container-offer {
        padding: 15px 19px;
    }

    .p-44 {
        padding: 44px 38px;
    }

    .p-17 {
        padding: 17px 19px 15px 0px;
    }

    .cont-acc {
        padding: 33px 22px 43px 22px;
    }

    .email-sent-sec {
        padding: 45px 10px 0px 10px;
    }

    .sale-offer-home {
        background: #FD9704;
        color: white;
        position: absolute;
        top: 36px;
        //left: 0px;
        padding: 8px 12px 6px 12px;
        -webkit-border-radius: 0px 20px 20px 0px;
        -moz-border-radius: 0px 20px 20px 0px;
        border-radius: 0px 20px 20px 0px;
        font-size: 18px;
        line-height: 23px;
        font-family: 'Futura PT D';
        z-index: 2;
    }

}

@media only screen and (max-width: 768px) and (min-width: 320px) {
    .carousel-inner {
        .box-offer {
            .carousel-item {
                .sale-offer-home {
                    top: 60%;
                }
            }
        }
    }
}

.pointer {
    cursor: pointer;
}

.excursion-box-offer {
    .mb(22);
}

.offerbyday-title {
    font-family: 'Futura PT';
    line-height: 23px;
    color: @black;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;

    .mb(3);
    overflow-y: hidden;

    span {
        line-height: 21px;
        font-family: 'Futura PT N';
        .ml(12);
    }
}

.icn-calendar-text {
    line-height: 18px;
    .mb(5);

    img {
        .pos-rel;
        top: -2px;
        .mr(8);
    }

    span {
        .ml(8);
        font-family: 'Futura PT D';
    }
}

.price-text {
    font-size: 16px;
    line-height: 21px;
    color: @black;
    .mb(0);
}

.price-green {
    color: @green;
    font-size: 16px;
    line-height: 26px;
}

.price-white {
    color: white;
    font-size: 20px;
    line-height: 26px;
    font-family: 'Futura PT D';
    .ml(8);
}

.price-offer {
    color: @orange;
    text-decoration: none;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Futura PT D';

    &:hover {
        text-decoration: underline;
    }
}


/*****************
SEARCH TAB
*****************/
.main-search {
    .form-group {
        height: 55px;

        &:first-child {
            height: auto;
        }
    }

    .hotel-search {
        .form-group {
            height: 55px;
        }
    }

    .tab-section {
        border:1px solid #1A8FE3;
    }
    .recomended-title {
        border-radius: 5px 5px 0px 0px;
        background: #FFF;
        position: relative;
        bottom: 26px;
        left: 0;
        width: 50%;
        padding: 5px 0px;
        text-align: center;
        margin: auto;
            span {
                color: #393939;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                font-family: 'Futura PT';
            }
    }
    form label[for="plecare-date"], form label[for="retur-date"], form label[for="passengar"] {
        width:100% !important;
    }
   .img-recommended-search {
    width:350px;
    height:200px;
    object-fit: cover;
   }
}

.flex-direction {
    flex-direction: column;
    display: flex;
}

.tab-section {
    // box-shadow: 0px 2px 10px rgba(11, 59, 167, 0.1);
    border-radius: 5px;
    .sec-brdr(@sec-brdr);
    background: white;
    padding: 12px 0px;

    .nav {
        border-bottom: 2px solid @light-brdr;
        justify-content: center;
    }

    .sejur-city-pills {
        border-bottom: 2px solid @pill-brdr;
        .ml(15);
        .mr(15);
    }

    .nav-item {
        display: inline;

        span {
            display: block;
            margin-top: 4px;
        }

        a {
            font-family: 'Futura PT D';
            font-size: 16px;
            line-height: 18px;
            color: @black;
            padding-top: 0px;
            padding-bottom: 0px;
            margin-bottom: -4px;
            .pos-rel;

            b {
                font-family: 'Futura PT';
            }

            &::after {
                .border-radius(30px);
                max-width: 85px;
                height: 5px;
                background: transparent;
                box-shadow: none;
                content: '';
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-top: 12px;
            }

            &.active {
                background: none;
                color: @orange;

                &::after {
                    box-shadow: 0px 6px 11px rgba(253, 151, 4, 0.25);
                    background: linear-gradient(90deg, @orange-grad, @orange-grad2);
                    color: @orange;
                }

                .black-icon {
                    display: none;
                }

                .orange-icon {
                    display: inline-block;
                }
            }

        }

        &.rentacar-tab {
            span {
                margin-top: -2px;
            }
        }
    }
}

.mt--7 {
    .mt(7);
}

.mt-15 {
    .mt(21);
}

.mb-15 {
    .mb(15);
}

.orange-icon {
    display: none;
}

.tab-content {
    padding: 4px 12px;
}

form {
    font-size: 14px;
    line-height: 21px;
    color: @black;

    .form-group {
        .mb(0);
    }

    label {
        width: 100%;

        span {
            position: relative;
            top: -30px;
            margin-left: 38px;
            padding-left: 1px;
            padding-right: 1px;
            color: @placeholder;
            transition: 0.5s;
        }
    }

    input.form-control, textarea.form-control, select.form-control {
        .border-radius(5px);
        border: 1px solid @inpt-brdr;
        height: 40px;
        padding-left: 38px;
        outline: none;

        &:hover {
            border: 1px solid @orange;
        }

        &:focus {
            border: 1px solid @orange;
            color: @black;
        }

        &:focus + span {
            padding-left: 3px;
            padding-right: 3px;
            top: -52px;
            color: @orange;
            font-size: 13px;
            line-height: 17px;
            background: white;
        }

        &.hover-input {
            border: 1px solid @orange;
        }

        &:-internal-autofill-selected {
            background: none;
        }
    }

    select.form-control {
        background: url('../../img/icons/chevron-down.svg') no-repeat;
        background-position: top 15px right 5px;

    }

    textarea.form-control {
        height: 170px;
        padding-left: 16px;

        &:focus + span {
            top: -180px;
        }

        & + span {
            top: -165px;

            &.focused-input {
                top: -180px;
            }
        }
    }

    label[for="checkbox"] {
        text-align: left;
        display: table;

        span {
            display: table-cell;
            color: white;
            margin-left: 0;
            font-size: 14px;
            line-height: 21px;
            top: -5px;
            left: 10px;
        }

        a {
            font-family: 'Futura PT';
            color: white;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        input {
            display: table-cell;
            height: 18px;
            width: 18px;

            &::before {
                background: url('../../img/check-orange.svg') no-repeat;
                background-position: left;
                height: 18px;
                width: 18px;
                content: '';
                display: inline-block;
                cursor: pointer;
            }

            &:hover {
                border: none;
            }

            &:checked {
                &::before {
                    background-position: right;
                }
            }

            &.hover-input {
                border: none;
            }
        }
    }
}

.focused-input {
    padding-left: 3px;
    padding-right: 3px;
    top: -52px;
    color: @placeholder;
    font-size: 13px;
    line-height: 17px;
    background: white !important;
}

#departure {
    background: url('../../img/icons/icon-departure.svg') no-repeat;
    background-size: 19px 19px;
    background-position: center left 10px;
}

#destination {
    background: url('../../img/icons/icon-destination.svg') no-repeat;
    background-size: 19px 19px;
    background-position: center left 10px;
}

#plecare-date {
    background: url('../../img/icons/icon-calendar.svg') no-repeat;
    background-size: 15px 14px;
    background-position: center left 10px;
}

#retur-date {
    background: url('../../img/icons/icon-calendar.svg') no-repeat;
    background-size: 15px 14px;
    background-position: center left 10px;
}

#passengar {
    background: url('../../img/icons/icon-people.svg') no-repeat;
    background-size: 16px 16px;
    background-position: center left 10px;
}

input[type="radio"] {
    width: 18px;
    height: 18px;
    .mr(6);
    .pos-rel;
    top: 4px;
    border: none;
    .mb(20);

    &::before {
        border-radius: 50%;
        border: 1px solid @brdr;
        width: 18px;
        height: 18px;
        content: '';
        display: inline-block;
        margin-right: 6px;
    }

    &:checked::before {
        background: url('../../img/icons/checked-radiobtn.svg') no-repeat;
        background-size: 18px 18px;
        border: none;
    }

    &:hover {
        border: none;
    }
}

.sale-offer-sec {
    .carousel-indicators {
        bottom: -30px;
        .mb(0);
    }

    //.box-offer {
    //  height: 100%;
    //}
}

#Carousel-sale {
    .carousel-inner {
        border: 1px solid @sec-brdr;

        .box-offer {
            border: none;
        }
    }
}

.sale-offer {
    background: @orange;
    color: white;
    .pos-abs;
    top: 36px;
    left: 0px;
    padding: 8px 12px 6px 12px;
    .border-radius(0px 20px 20px 0px);
    font-size: 18px;
    line-height: 23px;
    font-family: 'Futura PT D';

    img {
        .pos-rel;
        top: -3px;
    }

    span {
        margin: 0px 10px;
        text-transform: uppercase;
    }

    &.right-side {
        right: 0px;
        left: auto;
        .border-radius(20px 0px 0px 20px);
    }
}

.blue-bg {
    background: @blue;
}

.blue-clr {
    color: @blue !important;
}

.orange-clr {
    color: @orange;
}

.black-clr {
    color: @black;
}

.average-font {
    font-size: 16px;
    line-height: 21px;
    color: @black;
}

.small-font {
    font-size: 14px;
    line-height: 18px;
}

.pagination {
    justify-content: center;
    .mt(10);
    .mb(10);

    li {
        width: 29px;

        &.disabled {
            .page-link {
                background: transparent;
            }
        }
    }

    .page-link {
        border: none;
        font-size: 20px;
        line-height: 29px;
        color: @gray-page;
        height: 29px;
        width: 29px;
        display: inline-block;
        .border-radius(5px);
        outline: none;
        border: none;
        padding: 0px;
        text-align: center;
        background: transparent;

        i {
            color: @orange;
            line-height: 29px;
        }

        &.active {
            background: @orange;
            color: white;
        }
    }
}

.head-icon {
    img {
        .border-radius(50% 50% 0px 0px);
        padding: 17px;
        // background: linear-gradient(#ffffff 53%, transparent 50%);
        // background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.5, @sec-brdr), color-stop(0.5, transparent));
        // background-image: -moz-linear-gradient(center bottom, @sec-brdr 50%, transparent 50% );

        position: relative;
        box-shadow: 0px -1px 0px 0.18px @sec-brdr;
        background: white;
        top: 43px;
    }
}

.tab-content-heading {
    font-size: 16px;
    line-height: 21px;
    .mb(9);
}

.flow-column {
    flex-flow: column;
}

.sejur-city {
    cursor: pointer;
    .mb(15);

    img {
        height: 87px;
        background-size: cover;
        width: 100%;
    }

    .price-offer {
        text-decoration: none;

        &:hover {
            color: @orange;
            text-decoration: underline;
        }
    }
}

.br-10 {
    .border-radius(10px);
}

.small-size {
    line-height: 26px;
}

.sejur-city-img {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    display: block;
    height: 100%;

    img:hover {
        transform: scale(1);
        transition: transform 0.2s ease;

        &:hover {
            transform: scale(1.2);
            transition: transform 0.2s ease;
        }
    }
}

.nav-tabs {
    .nav-link {
        &.active {
            color: @orange;
        }

        &:hover {
            color: #ff8d07 !important;
        }
    }
}

.pr-10 {
    padding-right: 10px;
}

.pl-10 {
    padding-left: 10px;
}

/**** table ******/

.table {
    .mb(4);

    th {
        font-family: 'Futura PT D';

    }

    td, th {
        padding: 9px;
        //border-bottom: 1px solid @light-brdr !important;

        &:nth-child(1) {
            font-family: 'Futura PT D';
        }
    }

    tr {
        &:last-child {
            td {
                border-bottom: none !important;
            }
        }
    }

    a {
        color: @black;

        &:hover {
            color: @orange;
        }
    }

    &.calendar-table {
        tr {
            td {
                img {
                    .mr(10);
                    opacity: 0.6;
                }

                &:nth-child(1) {
                    width: 45%;
                    font-family: 'Futura PT N';
                }
            }

            td, th {
                border-bottom: 1px solid @inpt-brdr !important;
            }

            &:last-child {
                td {
                    border-bottom: none !important;
                }
            }
        }

        a {
            font-family: 'Futura PT D';
            color: @black;
            text-decoration: underline;

            &:hover, &:focus {
                color: @orange;
            }
        }
    }
}

.social-iframe {
    max-height: 130px;
    overflow-y: hidden;

    iframe {
        .border-radius(5px);

        ._2ph- {
            padding: 10px;
        }
    }
}

.focused-input {
    background: none;
}

/* newsletter */
.newsletter {
    background: url('../../img/newsletter-bg.png') no-repeat;
    background-size: cover;
    padding: 19px 0px 36px 0px;
    color: white;

    .supra-title {
        color: white;
    }

    .supra-subtitle {
        line-height: 24px;
        color: white;
    }

    input.form-control {
        background: none;
        border: none;
        border-bottom: 1px solid white;
        border-radius: 0;
        margin-bottom: 15px;
        padding-left: 4px;
        color: white;
        outline: none;

        &::placeholder {
            color: white;
        }

        &:hover {
            border: none;
            border-bottom: 1px solid white;
        }

        &:focus {
            border: none;
            border-bottom: 1px solid white;
            outline: none;
            color: white;
        }
    }

    .form-group {
        .ml(0);
    }

    button {
        position: absolute;
        right: 6px;
        margin-top: -48px;
        background: none;
        color: white;
        font-family: 'futura pt';

        img {
            margin-left: 5px;
            position: relative;
            top: -2px;
        }
    }

    form {
        label[for="checkbox"] {
            input {
                width: 20px;

                &::before {
                    background: url('../../img/check-white.png') no-repeat;
                    width: 20px;
                    height: 20px;
                }

                span {
                    color: white;
                }

                &:checked::before {
                    background-position: right;
                }
            }
        }
    }
}

.logo-detail {
    vertical-align: middle;
    .ml(15);
}

.logo-about {
    .mb(15);
}

.g-line-ft {
    .pos-rel;
    top: -19px;
    margin-left: 60px;
    width: calc(100% - 60px);
}


footer {
    .offerbyday {
        .mb(8);
    }

    li {
        .mb(5);

        a {
            color: @black;

            &:hover {
                color: @orange;
            }
        }
    }

    .offerbyday-title {
        span {
            font-family: 'Futura PT';
        }
    }

    .ft-contact {
        //columns: 2;
        //-webkit-columns: 2;
        //-moz-columns: 2;
        color: @black;

        li {
            .pos-rel;
            //.mb(25);
            padding-left: 23px;
            min-height: 45px;

            &:nth-child(2), &:nth-child(4) {
                .mb(0);
            }
        }

        a {
            color: @black;
            font-size: 14px;
            line-height: 18px;
            .mb(4);
        }
    }
}

.ft-tel {
    padding-left: 28px;

    &::before {
        content: '';
        background: url('../../img/icons/phone.svg') no-repeat;
        display: inline-block;
        height: 16px;
        width: 16px;
        .pos-abs;
        left: 0px;
    }

    a {
        font-family: 'Futura PT';
        display: block;
    }
}

.ft-location {
    padding-left: 23px;

    &::before {
        content: '';
        background: url('../../img/icons/cil_location-pin.svg') no-repeat;
        display: inline-block;
        height: 19px;
        width: 19px;
        .pos-abs;
        left: 0px;
    }
}

.ft-mail {
    padding-left: 23px;

    &::before {
        content: '';
        background: url('../../img/icons/mail.svg') no-repeat;
        display: inline-block;
        height: 20px;
        width: 20px;
        .pos-abs;
        left: 0px;
    }
}

.ft-social {
    a {
        .mr(15);

        &:hover {
            filter: drop-shadow(0px 4px 4px rgba(253, 141, 4, 0.36));
        }
    }
}

.footer-copyright {
    padding: 13px 5px;
    color: white;
}

.top-header .search-wrapper.d-flex.ms-auto.me-auto {
    width: 100%;
    left: auto;
}


//mobile base
.top-header {
    background: linear-gradient(@blue 80%, @body-bg 80% 100%);
    padding-top: 10px;
    padding-bottom: 10px;

    .main-logo {
        .border-radius(50%);
        border: 9px solid @blue;
    }

    .search-wrapper {

        &.d-flex.ms-auto.me-auto {
            top: 4px;
        }

        input {
            border: none;
            padding-left: 7px;

            &:focus {
                border: none;
            }

            &:hover {
                border: none;
            }

            &::placeholder {
                color: @placeholder;
            }
        }
    }

    .icon-bar {
        &:before {
            content: '';
            height: 2px;
            width: 37px;
            background: white;
            .border-radius(10px);
            display: block;
        }
    }
}

#navbar {
    text-align: right;
    position: fixed;
    right: 0px;
    width: 100%;
    bottom: 0px;
    top: 0px;
    height: 100%;
    z-index: 999;
    padding: 40px 15px;
    // backdrop-filter: blur(30px);
    // -webkit-backdrop-filter: blur(30px);
    //  -moz-backdrop-filter: blur(30px);
    background: @blue;
    color: white;

    a {
        display: block;
        padding: 8px 0px;
        border-bottom: 1px solid @very-light-brdr;
        color: white;
        font-size: 16px;
        line-height: 20px;

        i {
            .mr(10);
            .pos-rel;
            top: 2px;
        }

        &:hover {
            color: @orange;
        }
    }

    .navbar-nav {
        border-bottom: 1px solid @blue;
        padding-bottom: 20px;
        .mb(20);

        &:last-child {
            border-bottom: 0px;
            padding-bottom: 0px;
            .mb(0);
        }

        li {
            &:last-child {
                a {
                    border-bottom: 0px;
                }
            }
        }
    }

    .zmdi-close {
        display: block;
        text-align: right;
        margin-top: -25px;
        font-size: 30px;
        color: white;
        opacity: 0.6;
        cursor: pointer;
    }
}

.navbar-toggle {
    background: none;
    .pos-rel;
    top: 7px;
    padding: 0px;
}

.bold-text {
    font-family: 'Futura PT D';
    font-weight: bold;
}

.ui-menu {
    .ui-menu-item-wrapper {
        padding: 0px !important;
    }
}

.m-contul-meu {
    width: 15px;
    .mr(8);
    .pos-rel;
    top: -2px;
}

.autocomplete-list {
    display: none;
    position: absolute;
    background: white;
    padding: 15px;
    border: 1px solid #F3F3F3;
    box-shadow: 0px 5px 18px rgba(81, 81, 81, 0.15);
    z-index: 999;
    border-radius: 5px;
    margin-top: -10px;

    .ui-autocomplete {
        position: unset !important;
        width: auto !important;
        display: block !important;
    }

    // arrow on top of box
    &::after {
        z-index: -10;
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        margin-left: 0px;
        top: 0px;
        left: 40px;
        box-sizing: border-box;
        border: 8px solid #fff;
        border-color: transparent transparent #fff #fff;
        transform-origin: 0 0;
        transform: rotate(135deg);
        box-shadow: -9px 9px 18px 0 rgba(81, 81, 81, 0.15);
    }

    &::before {
        z-index: 10;
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        margin-left: 0px;
        top: 0px;
        left: 40px;
        box-sizing: border-box;
        border: 8px solid black;
        border-color: transparent transparent #fff #fff;
        transform-origin: 0 0;
        transform: rotate(135deg);
    }

    li {
        cursor: pointer;
        //background: url('../../img/icons/icon-departure.svg') no-repeat;
        background-size: 13px 13px;
        background-position: top 4px left;
        padding-left: 22px;
        margin-bottom: 0px;
        font-family: 'Futura PT N';
        padding-top: 2px;
        padding-bottom: 2px;
        font-size: 14px;
        line-height: 18px;
        color: @dark-blk;

        ul {
            border-left: 1px solid @ul-brdr;
            padding-left: 10px;
            margin-top: 4px;
            margin-bottom: 8px;

            li {
                margin-bottom: 0px;
                margin-top: 0px;
            }
        }
    }

    &.ui-widget.ui-widget-content {
        display: block;
        border: none;
        padding: 15px 0px;
        .mt(-2);

        li {
            padding: 4px 5px 4px 37px;
            background-position: top 7px left 15px;

            &:hover {
                background-color: @spacer;

                span {
                    background: @spacer;
                    border: none !important;
                    outline: none;
                    color: @dark-blk !important;
                }
            }

            span {
                font-family: 'Futura PT D';
                background: transparent;

                &:hover {
                    background: @spacer;
                    border: none !important;
                    outline: none;
                    color: @dark-blk !important;
                }
            }
        }
    }
}


.autocomplete-list-menu {
    display: none;
    position: absolute;
    background: white;
    padding: 15px;
    border: 1px solid #F3F3F3;
    box-shadow: 0px 5px 18px rgba(81, 81, 81, 0.15);
    z-index: 999;
    border-radius: 5px;
    margin-top: 0px;
    margin-left: 0 !important;


    .ui-autocomplete {
        position: unset !important;
        width: auto !important;
        display: block !important;
    }

    // arrow on top of box
    //&::after {
    //  z-index: -10;
    //  content: "";
    //  position: absolute;
    //  width: 0;
    //  height: 0;
    //  margin-left: 0px;
    //  top: 0px;
    //  left: 40px;
    //  box-sizing: border-box;
    //  border: 8px solid #fff;
    //  border-color: transparent transparent #fff #fff;
    //  transform-origin: 0 0;
    //  transform: rotate(135deg);
    //  box-shadow: -9px 9px 18px 0 rgba(81, 81, 81, 0.15);
    //}
    //
    //&::before {
    //  z-index: 10;
    //  content: "";
    //  position: absolute;
    //  width: 0;
    //  height: 0;
    //  margin-left: 0px;
    //  top: 0px;
    //  left: 40px;
    //  box-sizing: border-box;
    //  border: 8px solid black;
    //  border-color: transparent transparent #fff #fff;
    //  transform-origin: 0 0;
    //  transform: rotate(135deg);
    //}

    li {
        cursor: pointer;
        //background: url('../../img/icons/icon-departure.svg') no-repeat;
        background-size: 13px 13px;
        background-position: top 4px left;
        //padding-left: 22px;
        margin-bottom: 0px;
        font-family: 'Futura PT N';
        padding-top: 2px;
        padding-bottom: 2px;
        font-size: 14px;
        line-height: 18px;
        color: @dark-blk;

        ul {
            border-left: 1px solid @ul-brdr;
            padding-left: 10px;
            margin-top: 4px;
            margin-bottom: 8px;

            li {
                margin-bottom: 0px;
                margin-top: 0px;
            }
        }
    }

    &.ui-widget.ui-widget-content {
        display: block;
        border: none;
        padding: 15px 0px;
        .mt(-2);

        li {
            padding: 4px 5px 4px 37px;
            background-position: top 7px left 15px;

            &:hover {
                background-color: @spacer;

                span {
                    background: @spacer;
                    border: none !important;
                    outline: none;
                    color: @dark-blk !important;
                }
            }

            span {
                font-family: 'Futura PT D';
                background: transparent;

                &:hover {
                    background: @spacer;
                    border: none !important;
                    outline: none;
                    color: @dark-blk !important;
                }
            }
        }
    }
}

.album {
    .supra-title.black {
        margin-top: 0;
        font-size: 20px;
    }

    .supra-subtitle {
        h1, h2, h3, h4 {
            font-size: 1.2rem;
        }
    }
}

.add-album {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

label[for="file"] {
    cursor: pointer;
    .demi;
    font-size: 16px;
    line-height: 21px;
    color: @blue;
    .ml(15);
    .mb(30);

    img {
        .mr(4);
    }

    span {
        .pos-rel;
        top: 2px;
    }
}

.fig-caption {
    .mt(4);
    .mb(33);
}

.back-to-top {
    cursor: pointer;
    position: fixed;
    z-index: 999;
    background: linear-gradient(@orange-grad, @orange-grad2);
    box-shadow: 0px 6px 11px rgba(253, 151, 4, 0.3);
    border-radius: 50%;
    text-align: center;
    display: none;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.readmore-link {
    color: @blue;
    font-size: 16px;
    line-height: 21px;

    &:hover {
        color: @blue;
        text-decoration: underline;
    }
}

.main-heading {
    font-family: 'Futura PT';
    font-size: 32px;
    line-height: 41px;
    color: @dark-blk;
    .mt(27);
    .mb(27);
}


label[for="filter"] {
    span {
        font-size: 16px;
        line-height: 21px;
        color: @black;
        .demi;
    }

    select {
        width: 167px;
        height: 40px;
        font-size: 16px;
        line-height: 21px;
        padding: 0px 12px;
        color: @black;
        background: url('../../img/icons/dark-chevron-down.svg') no-repeat;
        background-size: 17px 17px;
        background-position: top 13px right 12px;
        border: 1px solid @sec-brdr;
        .border-radius(5px);
        .ml(12);
        appearance: none;
        background-color: white;
    }
}


/*simple text page*/
.simple-text-page {
    color: @dark-blk;
    font-size: 16px;
    line-height: 24px;
    .mb(80);

    p {
        color: @dark-blk;
        font-size: 16px;
        line-height: 24px;
    }

    h2 {
        .demi;
        .mb(15);
        .mt(25);
        font-size: 30px;
    }

    h3 {
        .mb(15);
        .mt(25);
        font-size: 26px;
        line-height: 32px;
        font-style: italic;
    }
}


/*listing*/
.listing-description {
    h2 {
        font-size: 18px;
        line-height: 23px;
        color: @black;
        font-family: 'Futura PT';
        .mb(12);
    }

    p {
        .average-font;
        .mb(18);
    }

    a {
        .average-font;
        color: @blue;

        &.white-clr {
            color: white;
        }
    }
}

.calatori-albume-page {
    .blue-clr {
        color: @blue;
    }

    .light-clr {
        color: @light-clr;
    }

    .listing-description {
        h2 {
            .mb(1);
        }

        p {
            .small-font;
        }

        a {
            font-size: 13px;
            line-height: 18px;
        }
    }
}


/*single articol*/
.single-description {
    h2 {
        font-family: 'Futura PT';
        color: @black;
        font-size: 24px;
        line-height: 30px;
        .mb(18);
    }

    p {
        .average-font;
    }

    li {
        .average-font;
        .mb(4);

        &:first-child {
            .mb(20);
        }
    }
}

.format {
    .average-font;
    .mb(10);
}

.angajare-contacti {
    .form-group {
        width: 100%;

        input {
            padding-left: 16px;

            &[type="checkbox"] {
                padding-left: 0px;
            }
        }

        span {
            .ml(16);
        }
    }

    label[for="file"] {
        .average-font;
        padding: 7px 24px;
        .border-radius(5px);
        border: 1px solid @blue;
        color: @blue;
        .mb(16);
        .ml(0);
        width: fit-content;
        font-family: 'Futura PT N';
    }

    label[for="checkbox"] {
        span {
            color: @black;
        }
    }
}

.feature-image {
    .border-radius(3px);
}


/*breadcrumbs*/

.breadcrumbs {
    li {
        font-size: 20px;
        line-height: 26px;
        color: @black;
        display: inline;

        a {
            font-family: 'Futura PT D';
            font-size: 24px;
            line-height: 31px;
            color: @black;
        }

        &::after {
            content: '';
            background: url('../../img/icons/left-arrow.svg') no-repeat;
            width: 17px;
            height: 17px;
            display: inline-block;
            .pos-rel;
            top: 2px;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }
}


/*login, register*/
.large-font {
    font-size: 24px;
    line-height: 30px;
    font-family: "Futura PT";

    &.heading {
        .mb(33);
    }

    &.reset-pass {
        .mb(0);
    }

    &.email-sent {
        font-family: 'Futura PT N';
        .pos-rel;
        top: -95px;
        background: white;
        font-size: 18px;
        line-height: 26px;
    }

    b {
        font-family: 'Futura PT D';
    }
}

.account {
    form {
        .form-group {
            display: block;
        }

        label[for="checkbox"] {
            span {
                color: @black;
            }
        }

        label[for="password"], label[for="c-password"] {
            .pos-rel;
        }

        input.form-control, select.form-control {
            font-size: 14px;
            line-height: 17px;
            padding-left: 15px;

            &[type="password"] {
                .pos-rel;
            }
        }

        .eye-icon {
            .pos-abs;
            top: 12px;
            right: 12px;
            cursor: pointer;
        }

        .saulgoin {
            border-top: 1px solid @brdr-line;
            .pos-rel;
            top: 14px;

            span {
                .ml(0);
                background: white;
                padding-left: 5px;
                padding-right: 5px;
                line-height: 24px;
                color: @black;
                .pos-rel;
                top: -14px;
            }
        }
    }
}

.aduaga-album-foto-page {
    form {
        label {
            span {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
}


.below-account-note {
    .small-font;
    .mb(11);

    a {
        text-decoration: underline;
    }
}

.facebook-login, .google-login {
    padding: 9px 10px;
    border: 1px solid @blue;
    .border-radius(3px);
    .small-font;
    .m(5);
    min-width: 103px;
    height: 37px;
    display: inline-block;

    img {
        .pos-rel;
        top: -2px;
        .mr(5);
    }
}


/*single calatorie album foto*/

.small-flower-img {
    .mb(6);
    width: calc(100% - 6px);
}

.small-flower {
    margin: 0px;

    img {
        .border-radius(5px);
    }
}

.big-flower {
    margin: 0px;
    .mb(6);
    width: calc(100% - 6px);

    img {
        .border-radius(5px);
    }
}

.spune {
    width: calc(100% - 6px);
}


/*aduaga album foto*/
.col-brdr {
    // border: 1px dashed @blue;
    width: calc(100% - 28px);
    .ml(14);
    .border-radius(5px);
    background: url('../../img/album/dashed-border.png') no-repeat;
    background-size: 100% 100%;
}

.centeralize {
    .pos-rel;
    top: 68%;
    transform: translateY(-50%);
    min-height: 245px;
}

.aduaga-album-foto-page {
    .box-offer {
        .container-offer {
            padding: 17px 14px;
        }
    }

    .listing-description {
        h2 {
            .demi;
            font-size: 17px;
            line-height: 22px;

            &.aduaga-title {
                font-size: 24px;
                line-height: 31px;
                font-family: 'Futura PT';
            }

            &.normal {
                font-family: 'Futura PT N';
            }
        }
    }

    form {
        .form-group {
            .mt(-6);
        }
    }
}

/*404 page */
.page-404 {
    .large-font.email-sent {
        top: -15px;
    }
}

@media screen and (min-width: 992px) {
    .blue-line-10x {
        height: 10px !important;
    }

    .w-300px {
        width: 300px !important;
    }
}

.blue-line-10x {
    height: 7px;
    width: 100%;
    background: #1A8FE3;
}

.group-heading {
    line-height: 31px;
    background: #1A8FE3;
    padding: 10px 29px;
    font-family: 'Futura PT';
    -webkit-border-radius: 5px 5px 0px 0px;
    -moz-border-radius: 5px 5px 0px 0px;
    border-radius: 5px 5px 0px 0px;
    color: white;
}

@media screen and (min-width: 380px) {
    .reservation-form label[for="checkbox"] span {
        font-size: 13px;
        line-height: 21px;
    }
}

.reservation-form label[for="checkbox"] span {
    color: #393939;
    font-size: 16px;
    line-height: 21px;
}

@media screen and (min-width: 768px) {
    .reservation-summary {
        position: sticky;
        top: 50px;
    }
}

#modalContract {
    .modal-content {
        max-height: 70vh;
    }
}

.reservation-summary {
    h2 {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 0px;
    }

    table {
        width: 100%;
        font-family: 'Futura PT N';

        tr {
            &.b-border {
                border-bottom: 1px solid #E3E3E3;
            }

            &:first-child {
                font-family: 'Futura PT M';
            }

            td:first-child {
                font-family: 'Futura PT M';
            }
        }

        td {
            font-size: 16px;
            line-height: 21px;
            padding-bottom: 15px;
            padding-top: 15px;
            text-align: left;
            color: @black;

            &.subtotal {
                vertical-align: top;
                font-size: 18px;
                line-height: 23px;
                color: #0C7C59;
                font-family: 'Futura PT D';
                text-align: right;
            }
        }

        ul {
            font-family: "Futura PT N";

            li {
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 5px;
            }
        }
    }
}

.select2-container--default {
    .select2-selection--single {
        height: 40px !important;
        padding-top: 5px;
        background: url('../../img/icons/chevron-down.svg') no-repeat;
        background-position: top 15px right 5px;
        background-color: #f8fafc !important;
        margin-left: 0;
        border: 1px solid @inpt-brdr !important;
        margin-left: 0px !important;

        &:hover {
            border: 1px solid @orange !important;
        }

        .select2-selection__arrow {
            display: none;
        }
    }

}

.select2-container--open {
    .select2-dropdown--below {
        border: 1px solid #f1f1f1;
        box-shadow: 0px 0px 10px -5px;
        border-radius: 5px;
    }
}

@margin-val: .25, .5, 1, 1.5, 3;

each(@margin-val, {
    .ml-@{index} {
        margin-left: ~'@{value}rem' !important;
    }

    .mt-@{index} {
        margin-top: ~'@{value}rem' !important;
    }

    .mb-@{index} {
        margin-bottom: ~'@{value}rem' !important;
    }

    .mr-@{index} {
        margin-right: ~'@{value}rem' !important;
    }

    .m-@{index} {
        margin: ~'@{value}rem' !important;
    }
});


.justify-space-between {
    justify-content: space-between;
}

.inside-box {
    margin: 10px
}


.inline-gallery-container {
    width: 700px;
    height: 500px;
    position: relative;
}


// For caption animation(Optional)
.lightGallery-captions {
    h4 {
        transform: translate3d(60px, 0, 0px);
    }

    p {
        transform: translate3d(-60px, 0, 0px);
    }

    h4,
    p {
        opacity: 0;
    }
}

.lg-current {
    .lightGallery-captions {
        h4,
        p {
            transform: translate3d(0, 0, 0px);
        }

        h4,
        p {
            opacity: 1;
        }
    }
}

.lg-slide-progress {
    .lightGallery-captions {
        h4 {
            transform: translate3d(-60px, 0, 0px);
        }

        p {
            transform: translate3d(60px, 0, 0px);
        }

        h4,
        p {
            opacity: 0;
        }
    }
}

.lightGallery-captions {
    h4,
    p {
        transition: transform 0.4s ease-in-out, opacity 0.4s ease-in;
    }
}

.lg-current {
    .lightGallery-captions {
        h4,
        p {
            transition-delay: 500ms;
        }
    }

    &.lg-slide-progress {
        .lightGallery-captions {
            h4,
            p {
                transition-delay: 0ms;
            }
        }
    }
}

.price_bula {
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    box-shadow: inset 20px 20px 20px rgb(0 0 0 / 5%), 25px 5px 20px rgb(0 0 0 / 5%),
    5px 10px 10px rgb(0 0 0 / 5%), inset -20px -20px 25px rgb(52 140 75 / 90%);
    border-radius: 50%;
    padding: 8px;
    align-content: center;
    flex-wrap: wrap;
    margin-left: 15px;
    background: #348C4B;
    color: white;
}

@media only screen and (max-width: 768px) and (min-width: 320px) {
    .price_bula {
        padding: 20px;
        margin-left: 0;
    }

    .custom-mobile-rez-bttn {
        margin-top: 30px;
    }
}

.scrollableTable {
    tr, th, td {
        border: 1px solid #b3afaf !important;
        padding: 5px !important;
    }
}

.moveTable {
    .scrollableTable tr td {
        width: 121px;
    }

    .moveleft {
        left: 95%;
        top: 25%;
    }

    .moveright {
        top: 25px;
    }
}

.lb-data .lb-caption {
    font-size: 20px !important;
    font-weight: 700;
    line-height: 1.5em !important;
    color: white;
}

.page-pachete-item {
    .tab-content {
        .inside-box {
            thead, tbody, tfoot, tr, td, th {
                border: 1px solid #b3afaf;
                text-align: center;
            }
        }
    }
}

.responsive-table {
    thead, tbody, tfoot, tr, td, th {
        border: 0 !important;
    }
}

@media (min-width: 320px) and (max-width: 1200px) {
    .table-custom-mobile {
        overflow: auto !important;
    }

    .scrollableTable {
        table-layout: initial !important;

        tr {
            th {
                .average-font;

                &:nth-child(1) {
                    position: relative !important;
                }
            }
        }
    }

    .table-custom-responsive {
        table {
            width: 100% !important;
        }
    }
}

.checked-meniu {
    color: #1A8FE3 !important;
}

@media (min-width: 320px) and (max-width: 768px) {
    .mb-2rem-mobile {
        margin-bottom: 2rem;
    }

    #navbar {
        text-align: center;
    }

    .bttn {
        font-size: 14px !important;
        padding: 5.5px 3px;
    }

    .checked-meniu {
        color: #635b5b !important;
    }

    label[for="filter"] select {
        font-size: 12px;
    }

    .distance-mobile {
        margin-left: 1rem;
    }

    .pachete-listing {
        .sale-offer {
            font-size: 9px;
        }
    }

    #navbar .zmdi-close {
        margin-right: 10px;
    }

    .carousel-mobile-custom {
        .box-offer {
            height: 205px;

            .img-offer {
                height: 100px;
            }
        }
    }

    .carousel-control-prev-icon, .carousel-control-next-icon {
        bottom: 0;
        position: absolute;
        background-color: #1A8FE3;
        border-radius: 50px;
        width: 20px;
        height: 20px;
    }

    .d-xs-grid {
        display: grid !important;
    }

    .calendar-homepage {
        .wrapper-nav {
            overflow: auto;
        }
    }

    .carousel-div-albume {
        .carousel-control-prev, .carousel-control-next {
            top: 20% !important;
        }
    }
}

.album.sharing-room {
    .text {
        position: absolute;
        z-index: 9;
        width: 100%;
        background: linear-gradient(180deg, rgba(249, 248, 255, 1) 0%, rgba(255, 255, 255, 0.7483368347338936) 31%, rgba(250, 251, 251, 0.5522584033613445) 62%, rgba(254, 254, 254, 0.036852240896358524) 100%);
    }

    .carousel-div-albume {
        max-width: 100%;
        margin: 0;
    }

    .slick-carousel-albume {
        .slick-slide {
            padding: 0;
            min-height: 100% !important;

            img {
                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    //background: rgb(255, 255, 255);
                    //background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.3617822128851541) 15%, rgba(255, 255, 255, 0) 100%);
                }
            }

            a {
                p {
                    transform: translate(0px, -7px);
                }
            }
        }

        height: 100%;

        .carousel-control-next {
            right: 0;
        }

        .carousel-control-prev {
            left: 0;
        }
    }

    .slick-slider {
        .slick-list {
            height: 100%;
        }

        .slick-track {
            height: 100%;
        }
    }
}

.asta-i-buton {
    color: white;
    background: #1A8FE3;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    float: right;
    padding: 9.5px 18px;
    text-decoration: none !important;

    &:hover {
        background: #35a3f3;
        color: white !important;
    }
}

.page-pachete-item {
    .box-offer {
        .order-lg-1 {
            .container-offer {
                thead, tbody, tfoot, tr, td, th {
                    //border-width: 1px !important;
                }
            }
        }
    }
}

.d-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.excursions {
    .excursii-slide {
        .carousel-control-prev {
            left: -25px;

            .gg-chevron-left {
                display: inline-block;
                width: 20px;
                height: 100px;
                left: 0;
                background-color: #FD9704;
                clip-path: polygon(58% 0, 19% 50%, 60% 100%, 0% 50%);
            }
        }

        .carousel-control-next {
            right: -25px;

            .gg-chevron-right {
                display: inline-block;
                width: 20px;
                height: 100px;
                left: 0;
                background-color: #FD9704;
                clip-path: polygon(58% 0, 100% 50%, 60% 100%, 81% 50%);
            }
        }
    }
}

.calendar-homepage {
    .scroller {
        .zmdi.zmdi-chevron-left,
        .zmdi.zmdi-chevron-right {
            font-size: 35px;
            cursor: pointer;
            padding-top: 5px;
        }
    }
}


.cookie-notification {
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    color: #333;
    padding: 20px;
    font-size: 16px;
    z-index: 9999;
    display: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    p {
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }

    #accept-cookie {
        background-color: #1A8FE3;
        color: #fff;
        border: none;
        padding: 10px 20px;
        margin-left: 10px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);

        &:hover {
            background-color: #1A8FE3;
            transform: scale(1.05);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
        }
    }
}

.grecaptcha-badge {
    display: none;
}
