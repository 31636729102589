.car-banner-search{
    padding: 60px 0px;
    form{
        .form-group{
            height: auto;
            .mt(-8);
        }
    }
}
.car-listing{
    padding: 22px 0px;
}
.car-top-bar{
    border: 1px solid @sec-brdr;
}
.car-notify{
    padding: 6px 10px;
    border: none;
    &.right-side{
        background: none;
    }
}
.make-logo{
    right: 27px;
}
.w-lg-80{
    width: 80% !important;
}
