.d-sm-inline-block{
    display: inline-flex;
}
.navbar-toggle{
    width: auto;
}
.top-header{
    padding-top: 2.4%;

    .search-wrapper{

        &.d-flex.ms-auto.me-auto{
            height: 40px;
        }
        input{
            height: 40px;
        }
        button{
            width: 42px;
            height: 35px;
            line-height: 35px;
        }
    }
}
.g-line-ft{
    margin-left: 80px;
    width: calc(100% - 80px);
}

.offerbyday-title{
    font-size: 18px;
    span{
        font-size: 16px;
    }
}

.icn-calendar-text{
    font-size: 14px;
}

.tab-section{
    .nav-item{
        a{
            padding-left: 21px;
            padding-right: 21px;
            font-size: 16px;
        }
        &:first-child{
            a{
                padding-left: 5px;
                sup{
                    top: 10px;
                }
            }
        }
        &:last-child{
            a{
                padding-right: 18px;
            }
        }
    }
    .sejur{
        .nav-item{
            a{
                padding-left: 18px;
                padding-right: 18px;
                sup{
                    .pos-rel;
                    top: -7px;
                    right: -5px;
                }
            }
            &:first-child{
                a{
                    padding-left: 5px;
                }
            }
            &:last-child{
                a{
                    padding-right: 18px;
                }
            }
        }
    }

}

.carousel-caption{
    right: 20%;
}


.box-offer{
    .p-44, .p-17{
        padding: 22px 20px;
    }
    .email-sent-sec{
        padding: 45px 33px 0px 33px;
    }
}
.main-heading{
    font-size: 24px;
}



.account{
    form{
        .saulgoin{
            span{
                font-size: 16px;
            }
        }
    }
}
.large-font{
    &.email-sent{
        font-size: 20px;
        line-height: 26px;
    }
}

.calatori-albume-page{
        .listin-description{
            a{
                font-size: 14px;
            }
        }
}
.listing-description{
    h2{
        font-size: 20px;
        line-height: 26px;
        .mb(3);
    }
}

