
/**************************
SECTION RULE VALUE START
**************************/

@full_section_value = 80;

/**************************
SECTION RULE VALUE END
**************************/


/*************************
CAROUSEL
*************************/

.carousel-inner{
    border-radius: 5px;
}

.carousel-caption{
    top: 15px;
    left: 15px;
    bottom: auto;
    padding: 12px 16px 19px 16px;
    p{
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 0px;
    }
}
.carousel-caption{
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(4px);
    border-radius: 10px;
    text-align: left;
}
