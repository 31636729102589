
/**************************
SECTION RULE VALUE START
**************************/

@full_section_value = 80;

/**************************
SECTION RULE VALUE END
**************************/
.top-header{
    .main-logo{
        width: 117px;
        border: 5px solid @blue;
    }
}
.abs{
    .pos-abs;
    .ml(25);
    margin-top: -45px;
}
.search-main{
    .mt(17);
}
.contact-info{
    padding-top: 22px;
}
.bottom-header{
    // margin-left: 135px;
    // .mr(32);
    .pos-rel;
    top: -7px;
    padding-bottom: 7px;
    ul{
        .ml(100);
        li{
            a{
                padding: 0px 13px 0;
                line-height: 15px;
                &:hover{
                    color: @blue !important;
                }
            }
        }
        &.account-menu{
            .ml(0);
        }
    }
}
.carousel-caption{
    right: 44%;
}
.slider-home{
    .container-offer{
        min-height: 100px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
}
.icn-calendar-text{
    font-size: 14px;
}
.box-offer{
    .container-offer{
        &.py-47{
            padding-top: 37px;
            padding-bottom: 37px;
        }
        &.p-17{
            padding: 15px 20px 15px 0px;
        }
    }
    .img-offer{
        height: 250px;
    }
}
.tab-section{
    padding: 4px 40px;
    .nav-item{
        a{
            font-size: 16px;
            line-height: 26px;
            br{
                display: none;
            }
        }
    }
    .sejur-city-pills {
        margin-top: 40px !important;
    }
}
.main-search{
    .tab-section{
        padding: 12px 0px;
    }
    .hotel-search{
        .form-group{
            &:first-child, &.occupy-group{
                //width: 26%;
            }
        }
    }
    .carSearch{
        .form-group{
            &:first-child{
                width: 100%;
            }
        }
    }
    .submitBtn-group{
        width: 13%;
    }
}

#Carousel-sale{
    .section-title{
        font-size: 16px;
        line-height: 21px;
    }
}
.mt--7{
    .mt(12);
}
.g-xl-0{
    padding-right: 0;
}


.table{
    td, th{
        font-size: 16px;
        line-height: 21px;
        &:nth-child(1){
            font-size: 18px;
            line-height: 23px;
        }
    }
}
.footer{
    .supra-title{
        font-size: 24px;
        line-height: 30px;
    }
}
form{
    .form-group{
        width: 168px;
        &:first-child{
            //width: 100%;
        }
    }
    &.newsletter-form{
        .form-group{
            width: auto;
        }
    }
}

.bttn{
    padding: 9.5px 16px;

    &.p-45{
        padding: 12px 16px;
    }
}
.album{
    .bttn{
        padding: 9.5px 30px;
    }
}
._1drm ._1z7u{
    width: 79%;
}
.offerbyday-title{
    font-size: 15px;
}
.sejur-tab-section{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: 0px;
}
.city-tab-section{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0px;

    &::before{
    content: '';
    height: calc(100% - 173px);
    display: block;
    width: 1px;
    border-left: 1px solid @sec-brdr;
    position: absolute;
    margin-left: -41px;
    margin-top: 40px;
    }
}


.listing-description{
    h2{
        line-height: 20px;
    }
}
.account{
    form{
        .form-group{
            display: block;
            width: auto;
        }
    }
}
.steps-list{
    ul{
        li{
            .mr(30);
            em{
                .ml(30);
            }
        }
    }
}
.carSearch{
    form{
        input.form-control{
            padding-left: 28px;
        }
        label{
            span{
                .ml(28);
            }
        }
    }
}
