.sale-offer{
    font-size: 12px;
}
.banner-with-search{
    padding: 60px 0px;
}

.listing-img{
    height: 100px;
}
.listing-pachete-description{
    h2{
        font-size: 15px;
        line-height: 17px;
    }
    p{
        font-size: 16px;
        line-height: 21px;
        .mb(18);
    }
    .icn-calendar-text {
        font-size: 14px;
        line-height: 18px;
        .mb(10);
    }
}

//.listing-pachete-price{
//    a{
//        font-size: 16px;
//        line-height: 21px;
//    }
//}

.w-lg-auto{
    width: auto;
}
.excursions-search{
    form{
        .form-group{
            .mt(0);
            width: calc(50% - 12px);
            display: inline-block;

            &.w-lg-auto{
                width: auto;
            }
        }
    }
}
.zboruri{
    font-size: 15px;
}

.xs-middle-font{
    font-size: 27px;
    line-height: 31px;
}
.autocar, .feature-desc, .m-average-font{
    font-size: 16px;
    line-height: 21px;
}
.bold-heading{
    font-size: 20px;
    line-height: 26px;
}
.island-partaje{
    height: 100%;
    border: 1px solid @sec-brdr;
    .border-radius(5px);
}
.no-border{
    border: none;
}

.moveleft, .moveright{
    left: 330px;
    z-index: 2;
    top: 25px;
    cursor: pointer;

    i{
        color: #D7D7D7;
    }
}
.moveright{
    left: auto;
    right: 15px;
    i{
        background: white;
        font-size: 22px;
        color: @orange;
    }
}
.no-scroll{
    overflow-x: hidden;;
}
// .lastItem{
//     transform: translateX(-330px);
// }
.scrollableTable{
    table-layout: fixed;
    overflow-x: none;
    text-align: center;
    thead{
        td{
            .average-font;
            font-family: 'Futura PT M';
            i{
                left: -15px;
                top: 3px;
                font-size: 22px;
                .pos-rel;
                color: @orange;
                cursor: pointer;
            }
        }
    }
    tr{
        td{
            transition: 0.5s;
            width: 116px;
            font-family: 'Futura PT M';
        }
        th{
            .average-font;
            &:nth-child(1){
                text-align: left;
                font-family: 'Futura PT M';
                position: sticky;
                z-index: 1;
                background: white;
                left: 0px;
                width: 320px;
            }
        }
        //&:last-child{
        //    th{
        //        &:first-child{
        //            border-bottom: 0px !important;
        //        }
        //    }
        //}
    }
}

.feature-img{
    .border-radius(0px 5px 5px 0px);
    object-fit: cover;
    height: 100%;
}
