
/**************************
SECTION RULE VALUE START
**************************/

@full_section_value = 80;

/**************************
SECTION RULE VALUE END
**************************/
