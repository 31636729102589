@import '../_variables.less';
@import '../_helpers.less';
@import '../_plugins.less';
@import '../_fonts.less';
@import '_base.less';

@media screen and (min-width: 380px) {
    @import '_380.less';
}

@media screen and (min-width: 576px) {
    @import '_576.less';
}

@media screen and (min-width: 768px) {
    @import '_768.less';
}

@media screen and (min-width: 992px) {
    @import '_992.less';
}

@media screen and (min-width: 1200px) {
    @import '_1200.less';
}

@media screen and (min-width: 1400px) {
    @import '_1400.less';
}

.tab-section-hotel {
    // box-shadow: 0px 2px 10px rgba(11, 59, 167, 0.1);
    border-radius: 5px;
    .sec-brdr(@sec-brdr);
    background: white;
    padding: 12px 0px;

    .content-hotel-search {
        form {
            .form-group {
                .mt(-6);
                display: inline-grid;
            }
        }
    }
}

.hotel-search {
    //background: @blue-dark;
    .form-group {
        height: 30px;

        &:first-child {
            height: auto;
        }
    }
}

.content-hotel-search {
    padding: 4px 12px;
    display: inline flex;
}

.listing-pachete-price {
    .normal {
        font-family: 'Futura PT N', serif;
        font-weight: 400;
    }
    p {
        font-size: 16px;
        line-height: 21px;
        font-family: 'Futura PT D', serif;

        &:last-child {
            font-size: 14px;
            line-height: 18px;
        }
        &.xl-large-font{
            font-weight: bold;
            font-family: none, serif;
        }
    }
}

.carousel-div-oferta.hotels {
    .slick-single-oferta .slick-slide {
        @media (max-width: 767px) {
            max-height: 200px;
        }

        @media (min-width: 768px) {
            max-height: 400px;
        }
    }
    .slick-carousel-oferta {
        .slick-arrow{
            @media (max-width: 767px) {
                max-height: 70px;
            }

            @media (min-width: 768px) {
                max-height: 100px;
            }
        }
        .slick-slide {
            @media (max-width: 767px) {
                max-height: 70px;
            }

            @media (min-width: 768px) {
                max-height: 100px;
            }
        }
    }
}
