.sub-citybreak{
    img{
        height: 200px;
    }
    .listing-description{
        h2{
            font-size: 17px;
            line-height: 21px;
        }
    }

}
.country-list{
    li{
        a{
            font-size: 14px;
            line-height: 17px;
        }
    }
}

.flights-data{
    .box-offer{
        width: 360px;
    }
}
.average-sm-font{
    .average-font;
}
