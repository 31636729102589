.listing-img{
    height: 125px;
    max-width: 125px;
}
.sale-offer{
    font-size: 14px;
}
.listing-pachete-description{
    h2{
        font-size: 18px;
        line-height: 23px;
        .mb(5);
    }
    p{
        .mb(21);
    }
    .icn-calendar-text {
        img{
            width: 25px;
        }
    }
}
//.listing-pachete-price{
//    a{
//        font-size: 16px;
//        line-height: 21px;
//    }
//}
.xl-large-font{
    font-size: 24px;
    line-height: 30px;
}
.zboruri{
    font-size: 16px;
}

/*pachete-item*/
.xs-middle-font{
    font-size: 32px;
    line-height: 41px;
}

.moveleft{
    left: 400px;
}
.scrollableTable{
    tr{
        td{
            width: 137px;
        }
        th:nth-child(1){
            width: 390px;
        }
    }

}
#nav-tab{
    overflow: hidden;
    .nav-link{
        font-size: 16px;
        width: 183px;
    }
}
