#nav-tab{
    border-bottom: none !important;
    overflow: auto;
    padding-top: 15px;
    .nav-link{
        opacity: 1;
        padding-bottom: 15px;
        background: @blue-dark;
        color: white;
        line-height: 21px;
        .border-radius(5px 5px 0px 0px);
        max-height: 55px;
        .mb(-2);
        img{
            width: 26px;
        }
        &::after{
            display: none;
        }
        &.active{
            color: @black;
            img{
                &.activeimg{
                    display: inline-block;
                }
                &.non-activeimg{
                    display: none;
                }
            }
        }
    }
}
