.excursions-search{
    form{
        .form-group{
            .mt(0);
            width: calc(50% - 12px);
            display: inline-block;
        }
    }
}

.hotel-search{
    form{
        .form-group{
            width: 100%;
            &.w-70{
                //width: 70%;
            }
        }
        label{
            &[for="plecare-date"], &[for="retur-date"]{
                width: 100%;
            }
        }
    }
}

//.listing-pachete-price{
//    p{
//        &:first-child{
//            .average-font;
//        }
//        &:last-child{
//            .small-font;
//        }
//    }
//    a{
//        font-size: 16px;
//        line-height: 21px;
//    }
//}

.listing-hotel-description{
    h2{
        font-size: 18px;
        line-height: 23px;
        &.facility-heading{
            .small-font;
            .demi;
        }
    }
    p{
        .demi;
        .average-font;

        &:last-of-type{
            .normal;
            .small-font;
        }
    }
}

//.sale-offer{
//    font-size: 12px;
//    left: auto;
//    right: 0;
//    border-radius: 20px 0px 0px 20px;
//    padding: 7px 2px 5px 7px;
//}

.feature-img{
    .border-radius(0px 5px 5px 0px);
    object-fit: cover;
    height: 100%;
}

.page-hotel-reservation{
    .price-green{
        font-size: 32px;
        line-height: 41px;
    }
    .m-average-font{
        font-size: 18px;
        line-height: 23px;
    }
}

#occupy{
    font-size: 13px;
    line-height: 25px;
    height: 40px;
}
//.carousel-item{
//    max-height: 270px;
//    height: 270px;
//    img{
//        .border-radius(0px 5px 0px 0px);
//    }
//}
.hotel-booking-price{
    .pos-abs;
    bottom: 0px;
    width: 263px;
}
