.d-sm-inline-block {
    display: inline-flex;
}

.main-heading {
    font-size: 32px;
    line-height: 41px;
    margin-top: 27px;
    margin-bottom: 25px;

    &.calatori-albume {
        .mb(20);
    }
}

.add-albume-calatori {
    .mb(20);
    .mt(42);
}

.cont-acc {
    padding: 33px 22px 53px 22px;
}

.account {
    form {
        .saulgoin {
            span {
                padding-left: 19px;
                padding-right: 19px;
                font-size: 18px;
            }
        }
    }
}

.large-font {
    &.email-sent {
        font-size: 24px;
        line-height: 31px;
    }
}

.single-description {
    h2 {
        font-size: 24px;
        line-height: 31px;
    }
}

#homepageCarousel {
    .carousel-caption {
        transform: translate(0,0) !important;
        right: 12vw !important;

    }
}
