//.listing-img{
//    max-width: 114px;
//    height: 130px;
//}
.listing-img{
    max-width: 114px;
    height: 130px;
}
.sale-offer{
    padding: 8px 6px 4px 6px;
    font-size: 12px;
    z-index: 1
}
.page-pachete-reservation{
    .m-average-font{
        font-size: 14px;
    }
}

.reservation-form{
    .form-group{
        &.radio-group{
            height: 54px;
            font-size: 16px;
        }
    }
    label[for="checkbox"]{
        span{
            .average-font;
        }
    }
}
.d-xs-block{
    display: flex !important;
}

