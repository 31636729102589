.banner-with-search {
    //background: @blue-dark;
    padding: 25px 0px;

    &.banner-hotels {
        background: #1374BA;
    }
}

.excursions-search {
    background: white;
    box-shadow: 0px 9px 10px rgba(11, 59, 167, 0.05);
    .border-radius(5px);
    padding: 20px 12px;

    form {
        .form-group {
            .mt(-3);
        }

        select {

            background: url('../../../img/icons/icon-calendar.svg') no-repeat, url('../../../img/icons/select-chevron-down.svg') no-repeat;
            background-size: 16px 16px, 17px 17px;
            background-position: center left 10px, top 11px right 10px;

            &#country {
                background: url('../../../img/icons/country-briefcase.svg') no-repeat, url('../../../img/icons/select-chevron-down.svg') no-repeat;
                background-position: center left 10px, top 11px right 10px;
            }
        }
    }

}


/*dropdown for multiple input*/
.adult-copi-camera {
    display: none;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid @brdr;
    position: absolute;
    background: white;
    z-index: 3;
    width: 100%;

    input {
        -moz-appearance: textfield;
        text-align: center;

        &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }

}


.adult-copi-camera-hotel {
    display: none;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid @brdr;
    position: absolute;
    background: white;
    z-index: 3;
    width: 100%;

    input {
        -moz-appearance: textfield;
        text-align: center;

        &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }

    .addNewRoom {
        color: #1a8fe3;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    .camera {
        color: #969dac;
    }

    .subtitle {
        font-size: 12px;
        color: #969dac;
        margin: 2px;
    }

    .btnRemove {
        color: #FF0000FF;
        float: right;
    }

    i {
        &.zmdi-plus,
        &.zmdi-minus {
            &:hover {
                color: rgba(253, 151, 4, 0.62);
            }
        }
    }
}

.dropdown-room {
    width: auto;
    min-width: max-content;

    .form-control {
        padding-right: 25px;
        width: 100%;
        min-width: max-content;
    }
}

label[for="occupy"] {
    i {
        cursor: pointer;
    }

    .plus-minus-input input {
        border: 1px solid @brdr;
        border-radius: 5px;
        width: 30%;
        height: 30px;
        font-size: 14px;
        margin-left: 5px;
        margin-right: 5px;
    }
}

em {
    font-style: normal;
}

#occupy {
    cursor: pointer;
    background: url('../../../img/icons/gray-user.svg') no-repeat, url('../../../img/icons/select-chevron-down.svg') no-repeat;
    background-position: center left 10px, top 11px right 10px;
    background-size: 19px 19px;
    border: 1px solid @inpt-brdr;
    padding-left: 38px;
}

#occupy + span {
    img {
        .ml(5);
        .mr(5);
    }
}

/* / dropdown with multiple input*/


.hotel-destination {
    background: url('../../../img/icons/icon-hotel-list.svg') no-repeat !important;
    background-position: center left 10px !important;
    background-size: 19px 19px !important;

    .placeholder-text {
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px;
        font-size: 16px;
        color: #999999;
        pointer-events: none; /* Evită interacțiunea cu textul de substituție */
        transition: all 0.2s ease;
    }

    .has-value .placeholder-text {
        transform: translateY(-100%);
        font-size: 14px;
        color: #CCCCCC;
    }
}

.hotel-destination-list-li {
    min-width: 300px;
    padding: 0;

    li {
        //background: url('../../../img/icons/icon-hotel-list.svg') no-repeat;
        color: black;
        padding: 15px;

        &.active {
            background-color: #d3d3d336;
        }

        //&:hover {
        //    color: blue;
        //    background-color: #d3d3d336;
        //}
    }
}

button.hoteluri:focus:not(:focus-visible) {
    background: #1a8fe3;
    color: white;
}

button.hoteluri:disabled {
    background-color: #1a8fe3 !important;
    color: #fff !important;
}

button.hoteluri{
    padding: 9.5px 15px;
}

.facility {
    li {

        display: inline;
        .ml(4);
        .mr(4);
    }
}

//.sale-offer{
//    font-size: 10px;
//    line-height: 15px;
//    padding: 8px 0px 4px 3px;
//    span{
//        margin: 0px 5px;
//    }
//    img{
//        width: 18px;
//    }
//}

.special-offer-box {
    border: 2px solid @orange;
}

.listing-hotel-description {
    .mb(10);

    h2 {
        font-size: 14px;
        line-height: 17px;
        color: @black;
        font-family: 'Futura PT';
        .mb(0);

        &.facility-heading {
            .medium-font;
            font-size: 12px;
            line-height: 15px;
        }
    }

    p {
        font-size: 12px;
        line-height: 15px;
        .medium-font;
        .mb(5);
    }
}

//.listing-pachete-price{
//    .normal{
//        font-family: 'Futura PT N';
//    }
//    a{
//        font-size: 12px;
//        line-height: 15px;
//    }
//    p{
//        .average-font;
//        &:first-child, &:last-child{
//            font-size: 12px;
//            line-height: 15px;
//        }
//    }
//}


/* filter sidebar widget with checkbox*/
.zmdi-star {
    color: #EECD20;
    font-size: 18px;
}

.sidebar-filter-widget {
    .group-heading {
        background-image: url(../../../img/icons/white-chevron-down.svg);
        background-position: center right 15px;
        background-repeat: no-repeat;
        background-color: @blue-dark;
        .border-radius(5px);
        cursor: pointer;
    }

    .sidebar-filter-content {
        display: none;
    }

    ul {
        .mb(20);
        padding-bottom: 12px;
        border-bottom: 1px solid @pill-brdr;

        &:last-of-type {
            border-bottom: none;
        }
    }

    li {
        .mb(8);
        .average-font;

        label {
            span {
                color: @black;
                display: inline-block;
                .ml(10);
                .average-font;
            }

            input {
                .pos-rel;
                top: 4px;
            }
        }

        &.mai-multe, &.up-mai-multe {
            color: @orange;
            cursor: pointer;

            i {
                color: @orange;
                .pos-rel;
                top: 3px;
            }
        }
    }
}

.hotel-listing-item {
    .listing-img {
        height: 100%;

        img {
            height: 100%;
            .border-radius(5px);
            object-fit: cover;
        }
    }
}


/*reservation*/
.rating-stars i {
    font-size: 22px;
}

.reservation-item {
    ul {
        li {
            font-family: 'Futura PT N';
            .mb(10);

            b {
                font-family: 'Futura PT M';
            }
        }
    }
}

.d-xs-block {
    display: block !important;
}

.page-hotel-reservation {
    .m-average-font {
        font-size: 12px;
    }

    .main-heading {
        font-size: 20px;
        line-height: 26px;
    }
}

.feature-img {
    width: 100%;
    .border-radius(5px 5px 0px 0px);
}

label[for="checkbox"] {
    text-align: left;
    display: table;

    span {
        display: table-cell;
        color: white;
        margin-left: 0;
        font-size: 14px;
        line-height: 21px;
        top: -5px;
        left: 10px;
    }

    a {
        font-family: 'Futura PT';
        color: white;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    input {
        display: table-cell;
        height: 18px;
        width: 18px;

        &::before {
            background: url('../../../img/check-orange.svg') no-repeat;
            background-position: left;
            height: 18px;
            width: 18px;
            content: '';
            display: inline-block;
            cursor: pointer;
        }

        &:hover {
            border: none;
        }

        &:checked {
            &::before {
                background-position: right;
            }
        }

        &.hover-input {
            border: none;
        }
    }
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
}

.datepickerClass {
    background: url('../../../img/icons/icon-calendar.svg') no-repeat !important;
    background-size: 15px 14px !important;
    background-position: center left 10px !important;
}

.from-time {
    background: url('../../../img/icons/from-time.svg') no-repeat !important;
    background-position: center left 10px !important;
}

.to-time {
    background: url('../../../img/icons/to-time.svg') no-repeat !important;
    background-position: center left 10px !important;
}

.booking-facility {
    ul {
        li {
            .small-font;
            .mb(7);

            img {
                .pos-rel;
                top: -3px;
            }
        }
    }
}

.averge-sm-font {
    .small-font;
}

.red-note {
    color: #FB3640;
}

.hotel-booking-table {
    img {
        .pos-rel;
        top: -3px;
    }

    th {
        padding: 15px;
        border: 0px;
        .demi;
        .average-font;

        &:nth-child(1) {
            .demi;
            .average-font;
        }
    }

    td {
        padding: 15px;
        border: 1px solid #EBEBEB !important;

        del {
            .normal;
        }

        li {
            .small-font;
            .normal;
            .mb(12);

            b {
                .demi;
            }
        }

        .bttn {
            color: white;
        }

        &:nth-child(1) {
            .bold;
            .average-font;
            border-left: 0px !important;
        }

        &:last-child {
            border-right: 0px !important;
        }
    }
}

.hotel-booking-price {
    position: unset;
}

.box-offer {
    .white-bg {
        .border-radius(5px);
    }
}
