.main-slider img{
    min-height: 280px;
}



.small-size{
    font-size: 20px;
    line-height: 25px;
}

.price-green{
    font-size: 19px;
    font-weight: 600;
}
.newsletter{
    input.form-control{
        padding-right: 35%;
    }
}
