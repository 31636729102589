.car-sidebar-filter-widget{
    .group-heading{
        cursor: auto;
        background-image: none;
        .heading-18;
        .demi;
    }
}
.success-detail{
    border-left: 1px solid @pill-brdr;
    padding-left: 30px;
}
