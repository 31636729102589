.account-sidebar{
    position: sticky;
    top: 50px;
    li{
        a{
            img{
                display: inline-block;
            }
        }
    }
}


/*reservation*/
.right-detail{
    .ml(17);
    h2{
        font-size: 18px;
        line-height: 23px;
    }
}
