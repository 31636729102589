.listing-img{
    height: 127px;
    max-width: 140px;
}
.excursions-search{
    form{
        .form-group{
            &.w-xxl-100{
                width: calc(100% - 19px);
            }
        }
    }
}
.scrollableTable{
    tr{
        td{
            width: 150px;
        }
        th:nth-child(1){
            width: 430px;
        }
    }
}

.moveleft{
    left: 440px;
}
#nav-tab{
    .nav-link{
        width: 202px;
        display: inline-block;
    }
}
