@font-face {
    font-family: 'Futura PT M'; // M = Medium
    src: url('../../fonts/Arial.otf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

.medium-font{
    font-family: 'Futura PT M';
}

.dark-green{
    color: #0C7C59;
}
.heading-18{
    font-size: 18px;
    line-height: 23px;
    .normal{
        font-family: 'Futura PT N';
    }
}
.heading-20{
    font-size: 20px;
    line-height: 26px;
    .zmdi-star{
        font-size: 23px;
    }
}
.extra-small-font{
    font-size: 12px;
    line-height: 15px;
}
.black-clr{
    color: @black;
}
