.filteration-by-date-price{
    cursor: pointer;
    .heading-18{
        font-size: 12px;
    }
    &::after{
        background: url('../../../img/icons/black-chevron-down.svg') no-repeat;
        background-position: right top -2px;
        background-size: 20px;
        transform: rotate(180deg);
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 20px;
    }
}
.flight-travelinfo{
    border-bottom: 1px solid @pill-brdr;
    .g-line{
        width: 18px;
    }
}
.no-brdr{
    border-bottom: 0px;
}
.selectati{
    box-shadow: 0px 6px 14px rgba(11, 59, 167, 0.25);
}

.page-flight-listing{
    .heading-20{
        font-size: 18px;
    }
}
.flight-sidebar-filter-widget{
    li{
        .pos-rel;
    }
    .right-price{
        position: absolute;
        right: 0;
        left: auto;
        line-height: 37px;
    }
}

.filteration-by-date-price-content{
    display: none;
    .mb(16);
}
.moveleft, .moveright{
    //left: 133px;
    z-index: 2;
    top: 30px;
    cursor: pointer;

    i{
        background: white;
        font-size: 22px;
        color: #D7D7D7;
    }
}
.moveright{
    left: auto;
    right: 15px;
    i{
        background: white;
        font-size: 22px;
        color: @orange;
    }
}
.no-scroll{
    overflow-x: hidden;;
}
.scrollableTable{
    table-layout: fixed;
    overflow-x: none;
    text-align: center;
    thead{
        td{
            .average-font;
            font-family: 'Futura PT M';
            i{
                left: -15px;
                top: 3px;
                font-size: 22px;
                .pos-rel;
                color: @orange;
                cursor: pointer;
            }
        }
    }
    tr{
        td{
            transition: 0.5s;
            width: 116px;
            font-family: 'Futura PT M';
        }
        th{
            .average-font;
            &:nth-child(1){
                text-align: left;
                font-family: 'Futura PT M';
                position: sticky;
                z-index: 1;
                background: transparent;
                left: 0px;
                width: 320px;
            }
        }
        &:last-child{
            th{
                &:first-child{
                    //width: 50px;
                }
            }
        }
    }
}
.offandon{
    display: none;
}
.cheapFlight{
    tr{
        td{
            width: 107px;
            vertical-align: middle;
            .small-font;
        }
        th{
            &:nth-child(1){
                width: 130px;
                .average-font;
                text-align: center;
                .small-font;
            }
        }
    }
}
.banner-with-search{
    //background: @blue-dark;
    padding: 25px 0px;
}
.box-offer{
    .content-hotel-search{
        padding: 25px 20px 2px 20px;
    }
}
.page-avion{
    .box-offer{
        position: unset;
    }
}
.average-sm-font{
    .small-font;
}
.dimensions{
    font-size: 12px;
    line-height: 15px;
}
.t--3{
    top: -3px;
}
