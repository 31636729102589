@import '_base.less';

@media screen and (min-width: 320px) {
    @import '_320.less';
}
@media screen and (min-width: 380px) {
    @import '_380.less';
}

@media screen and (min-width: 480px) {
    @import '_480.less';
}

@media screen and (min-width: 576px) {
    @import '_576.less';
}

@media screen and (min-width: 768px){
    @import '_768.less';
}

@media screen and (min-width: 992px){
    @import '_992.less';
}

@media screen and (min-width: 1200px) {
    @import '_1200.less';
}

@media screen and (min-width: 1400px) {
    @import '_1400.less';
}
