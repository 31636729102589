.main-slider img{
    min-height: 230px;
}



.small-size{
    font-size: 18px;
}
.price-green{
    font-size: 16px;
}
.newsletter{
    input.form-control{
        padding-right: 43%;
    }
}
