.bttn{
    &.brdr{
        font-size: 16px;
    }
}
.vin8{
    font-size: 24px;
    small{
        font-size: 16px;
    }
}
