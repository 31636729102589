.pachete-listing{
    label[for="filter"] select{
        width: 167px;
    }
}

/*pachete reservation*/
.reservation-item{
    ul{
        li{
            .average-font;
            .mb(7);
        }
    }
}
.page-pachete-reservation{
    .m-average-font{
        font-size: 18px;
        line-height: 23px;
    }
}
