
/**************************
GENERAL
**************************/

.default-letter-spacing {
    letter-spacing: 1px;
}

.pos-rel {
    position: relative;
}

.pos-abs {
    position: absolute;
}

.body-no-scroll {
    overflow: hidden;
    position: static;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.upper {
    text-transform: uppercase;
}

img {
    max-width: 100%;
    height: auto;
}

.background-cover {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.background-image(@image) {
    background-image: url('@{images}@{image}');
}

.backface-visibility(@value){
    -webkit-backface-visibility:    @value;
    -moz-backface-visibility:       @value;
    backface-visibility:            @value;
}

/**************************
TRANSITIONS
**************************/

@default-transition: all 0.4s ease;

.default-transition {

  -webkit-transition: @default-transition;
  -moz-transition:    @default-transition;
  -ms-transition:     @default-transition;
  -o-transition:      @default-transition;
  transition:         @default-transition;
}

.default-transform (@x:0, @y:0) {

  -webkit-transform: translate(@x, @y);
  -moz-transform:    translate(@x, @y);
  -ms-transform:     translate(@x, @y);
  -o-transform:      translate(@x, @y);
  transform:         translate(@x, @y);

}
.transform (@transform) {

  -webkit-transform: @transform;
  -moz-transform:    @transform;
  -ms-transform:     @transform;
  -o-transform:      @transform;
  transform:         @transform;

}

.transform-origin (@origin) {
	-webkit-transform-origin: @origin;
	-moz-transform-origin:    @origin;
	-ms-transform-origin:     @origin;
	-o-transform-origin:      @origin;
    transform-origin:         @origin;
}

.transition (@transition) {

  -webkit-transition: @transition;
  -moz-transition:    @transition;
  -ms-transition:     @transition;
  -o-transition:      @transition;
  transition:         @transition;
}

.transition-timing-function (@transition-timing) {
    -webkit-transition-timing-function: @transition-timing;
    -moz-transition-timing-function:    @transition-timing;
    -ms-transition-timing-function:     @transition-timing;
    -o-transition-timing-function:      @transition-timing;
    transition-timing-function:         @transition-timing;
}

.scale (@scale) {

  -webkit-transform: scale(@scale);
  -moz-transform:    scale(@scale);
  -ms-transform:     scale(@scale);
  -o-transform:      scale(@scale);
  transform:         scale(@scale);
}

.animation (@animation) {

  -webkit-animation:  @animation;
  -moz-animation:     @animation;
  -o-animation:       @animation;
  animation:          @animation;
}

.opacity(@opacity){

  -khtml-opacity: @opacity;
  -moz-opacity:   @opacity;
  opacity:        @opacity;

}

.box-shadow(@shadow){

  -webkit-box-shadow: @shadow;
  -moz-box-shadow:    @shadow;
  box-shadow:         @shadow;

}

.no-box-shadow {

  -webkit-box-shadow: none;
  -moz-box-shadow:    none;
  box-shadow:         none;

}

.border-radius(@radius) {

  -webkit-border-radius:  @radius;
  -moz-border-radius:     @radius;
  border-radius:          @radius;
}

.no-border-radius{

  -webkit-border-radius:  0;
  -moz-border-radius:     0;
  border-radius:          0;
}

.no-outline-no-shadows{
  -webkit-box-shadow: none;
  -moz-box-shadow:    none;
  box-shadow:         none;
  outline:            none;
}


/**************************
ANIMATIONS
**************************/

//fade in from below
.hover180Top {
    0% {
        .transform(rotateX(0));
    }
    100% {
        .transform(rotateX(180deg));
    }
}


@-webkit-keyframes  hover180Top { .hover180Top; }
@-moz-keyframes     hover180Top { .hover180Top; }
@-ms-keyframes      hover180Top { .hover180Top; }
@keyframes          hover180Top { .hover180Top; }

/**************************
MARGINS
**************************/

.m(@size){
    margin:~'@{size}px';
}
.mt(@size){
    margin-top:~'@{size}px';
}
.mr(@size){
    margin-right:~'@{size}px';
}
.mb(@size){
    margin-bottom:~'@{size}px';
}
.ml(@size){
    margin-left:~'@{size}px';
}

.no-scroll{
    overflow: hidden;
}
.abs-pos{
    position: absolute;
    margin-left: -33px;
    margin-top: -4px;
}
.line-through{
    text-decoration: line-through;
    color: @line-through;
}
.normal{
    font-family: "Futura PT N";
}
.small-font{
  font-size: 14px;
  line-height: 18px;
}
.average-font{
  font-size: 16px;
  line-height: 21px;
}
.middle-font{
  font-size: 20px;
  line-height: 26px;
}
.m-12-font{
    font-size: 12px;
    line-height: 18px;
}
.bold{
  font-family: 'Futura PT';
}
.br-5{
  .border-radius(5px);
}
@media (min-width: 320px) and (max-width: 1200px) {
    .d-320-1200-none{
        display: none;
    }
}
