.flight-travelinfo{
    .g-line{
        width: 40px;
    }

}
.cheapFlight{
    tr{
        td{
            width: 108px;
        }
    }
}
