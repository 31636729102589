.sale-offer{
    font-size: 11px;
}
.pachete-listing{
    label[for="filter"]{
        width: 100%;
        select{
            .ml(10);
            width: 167px;
        }
    }
}

#nav-tabContent{
    .average-font{
        font-size: 16px;
        line-height: 21px;
    }
    ul{
        .ml(0);
        li{
            font-size: 16px;
            line-height: 21px;
            background-position: left 5px top 8px;
            .mb(5);
        }
    }
    ol{
        .ml(0);
        li{
            font-size: 16px;
            line-height: 21px;
            .mb(5);
        }
    }
}

.island-partaje{
    position: sticky;
    top: 30px;
}

.bold-heading{
    &.sale-offer{
        font-size: 18px;
        line-height: 23px;
    }
}
