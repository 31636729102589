.flight-travelinfo{
    padding-right: 20px;
    border-right: 1px solid @pill-brdr;
    border-bottom: 0px;
}
.selectati{
    box-shadow: none;
}
.cheapFlight{
    tr{
        td{
            width: 104px;
        }
    }
}
.no-brdr{
    border-right: 0px;
}
