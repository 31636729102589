.excursions-search{
    form{
        .form-group{
            &.w-xxl-100{
                width: calc(100% - 19px);
            }
        }
    }
}
.hotel-booking-price{
    width: 255px;
}
