.cheapFlight{
    tr{
        td{
            width: 110px;
        }
    }
}
.dimensions{
    .small-font;
}
.average-sm-font{
    .average-font;
}
