.fulladdress{
    line-height: 32px;
    font-size: 16px;
    color: @dark-blk;
}


/*reservation*/

.left-img{
    min-width: 114px;
    max-width: 200px;
}
.right-detail{
    .ml(10);
}
