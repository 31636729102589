.account-sidebar{
    li{
        .mb(17);
        a{
            font-size: 16px;
            line-height: 21px;
            color: @dark-blk;
            &:hover{
                color: @orange;
            }

            img{
                .mr(10);
                width: 16px;
                .pos-rel;
                top: -2px;
                display: none;
            }
        }
        &.active{
            a{
                color: @orange;
            }
        }
        &:last-child{
            .mt(80);
        }
    }

}
.page-edit-account{
    form{
        .form-group{
            .pos-rel;
        }
    }
}
h2.middle-title{
    font-size: 20px;
    line-height: 21px;
    color: black;
    .demi;
}
h1.middle-title{
    font-size: 24px;
    line-height: 31px;
    color: black;
    .demi;
}
.fulladdress{
    line-height: 21px;
    font-size: 14px;
    color: @dark-blk;
}
.hr{
    background: @hrline;
    height: 1px;
}



/*reservation page*/
.left-img{
    min-width: 95px;
    text-align: center;
    align-self: center;
    img{
        .border-radius(5px);
    }
}
.right-detail{
    .ml(13);
    h2{
        font-size: 16px;
        line-height: 21px;
        color: @black;
        span{
            font-size: 14px;
            line-height: 17px;
            font-family: 'Futura PT N';
        }
    }
    ul{
        .mb(15);
        li{
            .demi;
            font-size: 14px;
            line-height: 17px;
            &:first-child{
                font-family: 'Futura PT N';
            }
        }
    }
    a{
        font-size: 14px;
        line-height: 17px;
    }

}
/*account reservation item*/
.blue-hr-line{
    border-top: 1px solid @blue;
    .mt(20);
    img{
        .pos-rel;
        top: -18px;
        background: @body-bg;
        padding-left: 20px;
        padding-right: 20px;
    }
}
.dropdown-toggle{
    &:focus{
        background: @blue;
    }
    &::after{
        display: none;
    }
    img{
        .pos-rel;
        top: -2px;
        margin-right: 5px;
    }
}
.float-right{
    float: right;
}
.pdf-menu{
    width: calc(100% - 25px);
    margin-top: 12px !important;
    padding: 20px 26px;
    filter: drop-shadow(0px 0px 20px rgba(11, 59, 167, 0.05));
    a{
        .small-font;
        text-decoration: underline;
        color: @blue;
        img{
            .mr(5);
            .pos-rel;
            top: -1px;
        }
    }
    span{
        font-size: 16px;
        line-height: 21px;
        color: @black;
        font-weight: 'Futura PT M';
    }
    li{
        .mb(7);
        ul{
            li{
                .mb(5);
            }
        }
    }
}

.reservation-item{
    h2{
        font-size: 18px;
        line-height: 23px;
    }
    p{
        .small-font;
        b{
            font-family: 'Futura PT M';
        }
        &.from-to{
            .average-font;
        }
        span{
            .small-font;
        }
    }
    ul{
        li{
            font-family: 'Futura PT N';
            .mb(10);
            b{
                font-family: 'Futura PT M';
            }
        }
    }
}


.flight-fromto{
    background: @body-bg;
    border: 1px solid @blue;
    padding: 13px;
    .border-radius(5px);
    color: @dark-blk;

    h2{
        font-size: 24px;
        line-height: 31px;
        color: black;
        .demi;
    }
}
.air-img{
    .pos-rel;
    top: 7px;
}

.modal {
    padding: 0 !important;
    .modal-dialog {
        width: 100%;
        max-width: none;
        height: 100%;
        margin: 0;
    }
    .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }
    .modal-body {
        overflow-y: auto;
    }
}

