.sidebar-filter-widget{
    .sidebar-filter-content{
        display: block;
    }
    .group-heading{
        .border-radius(5px 5px 0px 0px);
    }
}
.rating-stars i{
    font-size: 27px;
}
//.carousel-item{
//    max-height: 300px;
//    height: 300px;
//}
