/**************************
DEFAULTS
**************************/

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;700&display=swap');

.clearfix {
  zoom: 1;

  &:before, &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}


.fade {
    transition: opacity 0.5s ease-out;
}

.wrapper-nav * {
    opacity: 1;
}

img {
  &.img-align-right {
    float: right;
    margin: 0 0 30px 30px;
  }

  &.img-align-left {
    float: left;
    margin: 0 30px 30px 0;
  }

  &.img-align-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
}

a {
  img {
    &.img-align-right {
      float: right;
      margin: 0 0 30px 30px;
    }

    &.img-align-left {
      float: left;
      margin: 0 30px 30px 0;
    }

    &.img-align-center {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;
    }
  }
}


ul,
ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.dropdown {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn {
  &.active,
  &:active {
    .no-outline-no-shadows;
  }
}

a {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
    .no-outline-no-shadows;
  }
}

input,
textarea,
button,
.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;

  &:focus {
    .no-outline-no-shadows;
  }
}

::-webkit-input-placeholder {
  color: @placeholder;
}

:-moz-placeholder {
  color: @placeholder;
  .opacity(1);
}

::-moz-placeholder {
  color: @placeholder;
  .opacity(1);
}

:-ms-input-placeholder {
  color: @placeholder;
}

::-ms-input-placeholder {
  color: @placeholder;
}

::placeholder {
  color: @placeholder;
}

/**************************
VARIABLES
**************************/

@placeholder: #B6B6B6;
@light-blue: #e8f0f1;
@blue: #1A8FE3;
@black: #393939;
@gray: #636369;
@white: #FFFFFFFF;

@orange: #FD9704;
@orange-grad: #FF8D07;
@orange-grad2: #FFD600;

@hrline: #E8ECF3;
@yellow: #f7b32b;
@green: #32965D;
@light-brdr: #F9F9F9;
@inpt-brdr: #F1F1F1;
@pill-brdr: #F0F0F0;
@brdr: #D9D9D9;
@brdr-line: #DCDCDC;
@ul-brdr: #E3E3E3;
@dark-blk: #1A1A1A;
@spacer: #EEEEEE;
@sec-brdr: #EAEAEA;
@gray-page: #A5A5A5;
@body-bg: #F9FCFF;
@light-clr: #C0C0C0;
@line-through: #949494;
@blue-dark: #1374BA;

@very-light-brdr: rgba(220, 220, 220, 0.1);
@images: '../img/';


.light-clr {
  color: @light-clr;
}

.sec-brdr(@sec-brdr) {
  border: 1px solid @sec-brdr;
}

@font-face {
  font-family: 'Futura PT';
  src: url('../../fonts/ARIALBD.otf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT N'; // N = normal
  src: url('../../fonts/Arial.otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT D'; // N = normal
  src: url('../../fonts/Arial.otf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'Futura PT N';
  font-size: 14px;
  line-height: 17px;
  background: @body-bg;
}

.ml-3 {
  margin-left: 1rem;
}

.mb-0 {
  .mb(0);
}

.ml-0 {
  margin-left: 0px !important;
}

.mb-5rem {
    margin-bottom: 5rem !important;
}

.bttn {
  color: white;
  background: @blue;
  // box-shadow: 0px 6px 14px rgba(11, 59, 167, 0.25);
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  padding: 9.5px 5px;

  &:hover {
    color: white !important;
    background-color: #22a0fb !important;
    border: none;
  }

  &.p-45 {
    padding: 12px 16px;
  }
}

.gradient-line {
  .border-radius(30px);
  width: 70px;
  height: 5px;
  box-shadow: 0px 6px 11px rgba(253, 151, 4, 0.25);
  background: linear-gradient(90deg, @orange-grad, @orange-grad2);
  content: '';
  display: block;
  .mt(10);
  .mb(12);
}

sup {
  .pos-abs;
  right: 5px;
  top: 0;
  color: @placeholder;
}

.mt-20 {
  .mt(20);
}

.scroller {
  display: inline-block !important;
  opacity: 1 !important;
  border-bottom: 1px solid @sec-brdr;
  line-height: 35px;
  background-color: transparent;

  i {
    font-size: 18px;
    color: @orange;
    font-weight: bold;
    padding-top: 15px;
  }
}

a:hover {
  color: @black !important;
}

.green-clr {
  color: @green;
  font-weight: 600;
}

.wrapper-nav {
  border-bottom: 1px solid @sec-brdr;
  .mb(15);
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    padding: 5px;
    height: 50px;
    max-width: 90%;
}
.list {
    position: absolute;
    left: 0px;
    top: 0px;
    min-width: 3500px;
    margin-top: 0px;
}
.nav-tabs {
  .nav-link {
    background: transparent;
    border: none;
    font-size: 16px;
    color: @black;
    font-family: 'Futura PT D';
    padding: 7px 16px;

    &.active {
      background: transparent;

      &::after {
        .border-radius(30px);
        max-width: 70px;
        height: 5px;
        content: '';
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 12px;
        box-shadow: 0px 6px 11px rgba(253, 151, 4, 0.25);
        background: linear-gradient(90deg, @orange-grad, @orange-grad2);
        color: @orange;
      }
    }
  }
}

.demi {
  font-family: 'Futura PT D';
}

.underline {
  text-decoration: underline;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 5% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  height: 80%;
  overflow: auto;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.d-flex-center{
    display: flex !important;
    justify-content: center;
}

