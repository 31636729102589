.sub-citybreak{
    img{
        height: 229px;
    }
}
.sub-citybreak{
    .listing-description{
        h2{
            font-size: 18px;
            line-height: 23px;
        }
    }
}
.country-list{
    li{
        a{
            font-size: 16px;
            line-height: 21px;
        }
    }
}

.excursions-search{
    form{
        .form-group{
            .mt(0);
            display: inline-block;
        }
    }
}
#occupy{
    font-size: 13px;
    line-height: 25px;
    height: 40px;
}
.hotel-search{
    form{
        .form-group{
            width: 100%;
            &.w-70{
                //width: 66%;
            }
        }
        label{
            &[for="plecare-date"], &[for="retur-date"]{
                width: 100%;
            }
        }
    }
}
.mt-n3{
    margin-top: -30px;
}
.g-line{
    img{
        width: 30px;
    }
}
